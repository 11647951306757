import { AxiosResponse } from "axios"
import {
  ICloseWorkOrder,
  ICreateComment,
  IGetWorkOrderByCase,
  IUpdateExecutorRole,
} from "common/types/workOrder/workOrder.type"

import http, { handleError } from "./utilities/http-common"
const url = "workorders"

class WorkOrderService {
  getWorkOrderOnCaseId = (caseId: string): Promise<IGetWorkOrderByCase[]> =>
    http
      .get(`/${url}/${caseId}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  closeWorkOrder = (data: ICloseWorkOrder): Promise<AxiosResponse> =>
    http.patch(`/${url}/close`, data).then(response => response)

  createComment = (data: ICreateComment): Promise<AxiosResponse> =>
    http.post(`/${url}`, data).then(response => response)

  updateExecutorRole = (data: IUpdateExecutorRole): Promise<AxiosResponse> =>
    http.patch(`/${url}/updateExecutorRole`, data).then(response => response)
}

export default new WorkOrderService()
