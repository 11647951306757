import { IPermissions } from "common/types/permission/permission.type"

import http, { handleError } from "./utilities/http-common"
const url = "permissions"

class PermissionService {
  getPermissions = (): Promise<IPermissions> =>
    http
      .get(`/${url}`)
      .then(response => response.data)
      .catch(error => handleError(error))
}

export default new PermissionService()
