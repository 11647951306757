import { DeDUPage } from "@dedu-internal/dedu-ui"
import { CustomerCommandServicePermissionFlags } from "common/permissionFlags/CustomerPermissionFlags"
import { GetAllPermissions } from "common/permissions/dedu-permissions"
import PermissionService from "common/services/tsApi/permission.service"
import { IPermissions } from "common/types/permission/permission.type"
import React, { cloneElement, FC, ReactNode, useEffect, useState } from "react"

const hasPermission = (
  myPermissions: IPermissions,
  permission: CustomerCommandServicePermissionFlags,
  serviceName: string
) => {
  if (!myPermissions) {
    return false
  }
  try {
    const allPermissions = GetAllPermissions()

    const myPermissionsForService = myPermissions[serviceName]
    const allPermissionsForService = allPermissions[serviceName]
    let permissionToCheck = {}

    permissionToCheck = allPermissionsForService[permission]
    return myPermissionsForService.some(
      p => p === permissionToCheck || p === "All"
    )
  } catch (err) {
    console.warn("No such permission in service with that name", err) //eslint-disable-line no-console
  }
  return false
}

interface Props {
  RenderError?: () => JSX.Element
  children: ReactNode
  errorProps?: () => JSX.Element
  permission: CustomerCommandServicePermissionFlags
  serviceName: string
}

const PermissionGate: FC<Props> = ({
  children,
  errorProps = null,
  permission,
  RenderError = () => <></>,
  serviceName,
}) => {
  const [myPermissions, setMyPermissions] = useState<Record<string, string[]>>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getPermissions()
  }, [])

  const getPermissions = () => {
    PermissionService.getPermissions()
      .then(data => {
        setMyPermissions(data)
        setIsLoading(false)
      })
      .catch(error => {
        console.error("service_permission error", error)
      })
  }

  const permissionGranted = hasPermission(
    myPermissions!,
    permission,
    serviceName
  )

  if (isLoading) return <DeDUPage isLoading />

  if (!permissionGranted && !errorProps) return <RenderError />

  let childrenWithProps: ReactNode = null

  if (children) {
    childrenWithProps = React.Children.map(children, (child, index) =>
      cloneElement(child as React.ReactElement, { ...errorProps, key: index })
    )
  } else if (React.isValidElement(children)) {
    childrenWithProps = cloneElement(children, { ...errorProps })
  }

  return (
    <>{!permissionGranted && !!errorProps ? childrenWithProps : children}</>
  )
}

export default PermissionGate
