import {
  DeDUAlert,
  DeDUCard,
  DeDUForm,
  DeDUGrid,
  DeDUPage,
} from "@dedu-internal/dedu-ui"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  customerCmdName,
  CustomerCommandServicePermissionFlags,
} from "common/permissionFlags/CustomerPermissionFlags"
import PermissionGate from "common/permissions/PermissionGate"
import customerService from "common/services/tsApi/customer.service"
import { IUpdateCustomer } from "common/types/customer/customer.type"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

const OrganizationName = () => {
  const { t } = useTranslation()
  const [hasPermission, setHasPermission] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const customer = useQuery(["customer"], () =>
    customerService.getCustomer().then(res => res)
  )

  const nameMutation = useMutation({
    mutationFn: (values: IUpdateCustomer) => {
      return customerService.updateName(values)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["customer"])
    },
  })

  const onSubmit = (values: IUpdateCustomer) => {
    return nameMutation.mutateAsync(values)
  }

  return (
    <DeDUPage
      containerProps={{ fluid: true }}
      title={t("common.OrganizationName") || ""}
    >
      <PermissionGate
        permission={CustomerCommandServicePermissionFlags.CanUpdateCustomer}
        RenderError={() => {
          setHasPermission(false)
          return (
            <DeDUAlert type="warning">
              {t("organization.NoPermissionNameChange")}
            </DeDUAlert>
          )
        }}
        serviceName={customerCmdName}
      >
        <DeDUGrid>
          <DeDUGrid.Col md={6} span={12}>
            <DeDUCard isLoading={customer.isInitialLoading}>
              <DeDUForm<IUpdateCustomer>
                items={[
                  {
                    disabled: hasPermission,
                    key: "customerName",
                    label: t("common.OrganizationName"),
                    schema: Yup.string()
                      .min(2, t("organization.NameRequired"))
                      .required(t("organization.NameRequired")),
                    type: "text",
                    value: customer.data?.customerName,
                  },
                ]}
                submit={{
                  onSubmit: onSubmit,
                  resolveErrorMessage: () => t("organization.NameUpdateFail"),
                  resolveSuccessMessage: () => t("organization.NameUpdated"),
                }}
                submitButton={{ label: t("common.Edit") }}
              />
            </DeDUCard>
          </DeDUGrid.Col>
        </DeDUGrid>
      </PermissionGate>
    </DeDUPage>
  )
}

export default OrganizationName
