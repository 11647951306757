import {
  DeDUAccordion,
  DeDUAlert,
  DeDUCard,
  DeDUDivider,
  DeDUGrid,
  DeDUPage,
  DeDUText,
} from "@dedu-internal/dedu-ui"
import { useQuery } from "@tanstack/react-query"
import objectModelService from "common/services/tsApi/objectModel.service"
import { IObjectModelTemplate } from "common/types/objectModel/objectModel.type"
import { Fragment, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Tree, TreeNode } from "react-organizational-chart"

const DEFAULT_TEMPLATE = "Fi2ObjectModel"

const ViewStandard = () => {
  const { t } = useTranslation()
  const [template, setTemplate] = useState<IObjectModelTemplate>()

  const { data, error, isFetching, isInitialLoading } = useQuery(
    ["defaultObjectModelTemplate"],
    () =>
      objectModelService
        .getObjectModelTemplates()
        .then(res => res.find(r => r.templateName === DEFAULT_TEMPLATE))
  )

  useEffect(() => {
    setTemplate(data)
  }, [data])

  const sortedTemplateData = useMemo(() => {
    return [...(template?.modelItemTemplatesData || [])].sort((a, b) => {
      const relation = template?.relationTemplatesData?.find(
        r => r.fromLabel === a.itemLabel && r.toLabel === b.itemLabel
      )

      if (relation?.templateName === "to_parent") {
        return -1
      }

      return 1
    })
  }, [template])

  const TreeNodes = () => {
    return (
      <>
        {sortedTemplateData.reduce(
          (
            acc: JSX.Element | null,
            data: (typeof sortedTemplateData)[number],
            index: number
          ) => {
            return (
              <TreeNode
                key={index}
                label={<div className="node">{data.templateName}</div>}
              >
                {acc}
              </TreeNode>
            )
          },
          null
        )}
      </>
    )
  }

  return (
    <DeDUPage
      containerProps={{ fluid: true }}
      title={t("objectModel.objectModelStandard") || ""}
    >
      {error ? (
        <DeDUAlert mb={20} type="error" variant="outline">
          {t("common.ErrorMessage")}
        </DeDUAlert>
      ) : null}

      <DeDUGrid columns={2} grow>
        <DeDUGrid.Col md={1}>
          <DeDUCard
            isLoading={isFetching || isInitialLoading}
            radius="md"
            withBorder
          >
            <DeDUText weight="bold">{template?.templateName}</DeDUText>
            <DeDUCard.Section my="md">
              <DeDUDivider />
            </DeDUCard.Section>
            <Tree label="">
              <TreeNodes />
            </Tree>
          </DeDUCard>
        </DeDUGrid.Col>

        <DeDUGrid.Col md={1}>
          <DeDUCard
            isLoading={isFetching || isInitialLoading}
            radius="md"
            withBorder
          >
            <DeDUText weight="bold">{t("common.Attributes")}</DeDUText>
            <DeDUCard.Section my="md">
              <DeDUDivider />
            </DeDUCard.Section>
            <DeDUAccordion variant="contained">
              {[...sortedTemplateData].reverse().map((templateData, i) => (
                <DeDUAccordion.Item
                  key={i}
                  value={templateData.itemLabel || ""}
                >
                  <DeDUAccordion.Control>{`${templateData.templateName} (${templateData.itemLabel})`}</DeDUAccordion.Control>
                  <DeDUAccordion.Panel>
                    {templateData.childTemplatesData?.map((childData, j) => (
                      <Fragment key={j}>
                        {childData.complexData ? (
                          <DeDUAccordion variant="contained">
                            <DeDUAccordion.Item
                              value={childData.complexData.templateName || ""}
                            >
                              <DeDUAccordion.Control>{`${childData.complexData.templateName} (ComplexType)`}</DeDUAccordion.Control>
                              <DeDUAccordion.Panel>
                                {childData.complexData?.childTemplatesData?.map(
                                  (grandChildData, k) => (
                                    <div
                                      key={k}
                                    >{`${grandChildData.simpleData?.templateName} (${grandChildData.simpleData?.instanceTypeName})`}</div>
                                  )
                                )}
                              </DeDUAccordion.Panel>
                            </DeDUAccordion.Item>
                          </DeDUAccordion>
                        ) : (
                          <div>{`${childData.simpleData?.templateName} (${childData.simpleData?.instanceTypeName})`}</div>
                        )}
                      </Fragment>
                    ))}
                  </DeDUAccordion.Panel>
                </DeDUAccordion.Item>
              ))}
            </DeDUAccordion>
          </DeDUCard>
        </DeDUGrid.Col>
      </DeDUGrid>
    </DeDUPage>
  )
}

export default ViewStandard
