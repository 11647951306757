import { DeDUGrid, DeDUPage } from "@dedu-internal/dedu-ui"
import { useQuery } from "@tanstack/react-query"
import objectModelService from "common/services/tsApi/objectModel.service"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import CreateGroup from "./group/CreateGroup"
import GroupList from "./group/GroupList"

const ObjectModelGroups = () => {
  const { t } = useTranslation()

  const { data, isLoading } = useQuery(["objectModel"], () =>
    objectModelService.getObjectModels().then(res => res || [])
  )

  const objectModelId = useMemo(() => {
    return data?.[0]?.modelId || undefined
  }, [data])

  return (
    <DeDUPage
      containerProps={{ fluid: true }}
      isLoading={isLoading}
      title={t("objectModel.groups.title")}
    >
      <DeDUGrid align="top" columns={2} grow>
        <DeDUGrid.Col md={1}>
          <CreateGroup objectModelId={objectModelId} />
        </DeDUGrid.Col>

        <DeDUGrid.Col md={1}>
          <GroupList objectModelId={objectModelId} />
        </DeDUGrid.Col>
      </DeDUGrid>
    </DeDUPage>
  )
}

export default ObjectModelGroups
