// store.js
import { createContext, useReducer } from "react"

const initialState = { selectedCustomer: null }
const store = createContext(initialState)
const { Provider } = store

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "onCustomerRetrieved":
        return { ...state, selectedCustomer: action.payload }
      case "onCustomerUpdated":
        return { ...state, selectedCustomer: action.payload }
      default:
        throw new Error()
    }
  }, initialState)

  return <Provider value={{ dispatch, state }}>{children}</Provider>
}

export { StateProvider, store }
