import "./MainLayout.css"

import {
  DeDUAppShell,
  DeDUBox,
  DeDUButton,
  DeDUCenter,
  DeDUGrid,
  DeDUImage,
  DeduLoadingOverlay,
  DeDUNavLink,
  DeDUSpotlightAction,
  DeDUStack,
  DeDUText,
} from "@dedu-internal/dedu-ui"
import {
  IconBinaryTree2,
  IconBuildingCommunity,
  IconHome,
  IconHomeSearch,
  IconList,
  IconLockCog,
  IconLogout2,
  IconPlus,
  IconSettings,
  IconTool,
  IconUserCheck,
  IconUserExclamation,
  IconUsers,
} from "@tabler/icons-react"
import { useQuery } from "@tanstack/react-query"
import customerService from "common/services/tsApi/customer.service"
import identityService from "common/services/tsApi/identity.service"
import { FC, PropsWithChildren, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"

import packageInfo from "../../package.json"
import { StaleTime } from "./StaleTime"

const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  const [stateLogoutUrl, setStateLogoutUrl] = useState("bff/logout")
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const history = useHistory()
  const [opened, setOpened] = useState(false)
  const [notificationsOpened, setNotificationsOpened] = useState(false)
  const [activePath, setActivePath] = useState(history.location.pathname)

  useEffect(() => {
    ;(async () => {
      await fetchIsUserLoggedIn()
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchIsUserLoggedIn = async () => {
    try {
      setLoading(true)
      const response = await fetch("bff/user", {
        headers: {
          "X-CSRF": "1",
        },
      })

      if (response.ok && response.status === 200) {
        const data = await response.json()
        const logoutUrl =
          data.find(
            (claim: { type: string; value: string }) =>
              claim.type === "bff:logout_url"
          )?.value ?? stateLogoutUrl
        setLoggedIn(true)
        setStateLogoutUrl(logoutUrl)
      }
      setLoading(false)
    } catch (e) {
      setLoggedIn(false)
      setLoading(false)
    }
  }

  const identity = useQuery(["loggedInUser"], () =>
    identityService.getLoggedInUser().then(res => res)
  )

  const { data, isLoading } = useQuery(
    ["customer"],
    () => customerService.getCustomer().then(res => res),
    {
      staleTime: StaleTime.Long,
    }
  )

  history.listen(({ pathname }) => {
    setActivePath(pathname)
    setOpened(false)
    setNotificationsOpened(false)
  })

  const spotlightActions: DeDUSpotlightAction[] = [
    {
      description: t("spotlightActions.casesDescription"),
      icon: <IconList />,
      onTrigger: () => history.push("/cases"),
      title: t("case.Cases"),
    },
    {
      description: t("spotlightActions.myCasesDescription"),
      icon: <IconList />,
      onTrigger: () => history.push("/cases/mine"),
      title: t("case.MyCases"),
    },
    {
      description: t("spotlightActions.createCaseDescription"),
      icon: <IconPlus />,
      onTrigger: () => history.push("/cases/create"),
      title: t("case.CreateCase"),
    },
    {
      description: t("spotlightActions.viewStandardDescription"),
      icon: <IconBinaryTree2 />,
      onTrigger: () => history.push("/objectmodel/standard"),
      title: t("objectModel.ViewStandard"),
    },
    {
      description: t("spotlightActions.createObjectDescription"),
      icon: <IconPlus />,
      onTrigger: () => history.push("/objectmodel/create"),
      title: t("objectModel.CreateItem"),
    },
    {
      description: t("spotlightActions.viewModelDescription"),
      icon: <IconHomeSearch />,
      onTrigger: () => history.push("/objectmodel/view"),
      title: t("objectModel.ViewModel"),
    },
    {
      description: t("spotlightActions.groupsDescription"),
      icon: <IconBuildingCommunity />,
      onTrigger: () => history.push("/objectmodel/groups"),
      title: t("objectModel.groups.title"),
    },
    {
      description: t("spotlightActions.editOrganizationDescription"),
      icon: <IconSettings />,
      onTrigger: () => history.push("/org/name"),
      title: t("spotlightActions.editOrganizationTitle"),
    },
    {
      description: t("spotlightActions.organizationExecutorRolesDescription"),
      icon: <IconTool />,
      onTrigger: () => history.push("/org/roles"),
      title: t("spotlightActions.organizationExecutorRolesTitle"),
    },
    {
      description: t("spotlightActions.organizationUserAccountsDescription"),
      icon: <IconUsers />,
      onTrigger: () => history.push("/org/accounts"),
      title: t("spotlightActions.organizationUserAccountsTitle"),
    },
    {
      description: t("spotlightActions.permissionsDescription"),
      icon: <IconLockCog />,
      onTrigger: () => history.push("/permissions"),
      title: t("menu.Permissions"),
    },
    {
      description: t("spotlightActions.myExecutorRolesDescription"),
      icon: <IconUserCheck />,
      onTrigger: () => history.push("/user/roles"),
      title: t("spotlightActions.myExecutorRolesTitle"),
    },
    {
      description: t("spotlightActions.MyAreaResponsibilitiesDescription"),
      icon: <IconUserExclamation />,
      onTrigger: () => history.push("/user/area-responsibility"),
      title: t("spotlightActions.MyAreaResponsibilitiesTitle"),
    },
  ]

  return (
    <div id="main-layout">
      <DeduLoadingOverlay
        data-testid="overlay"
        overlayBlur={10}
        visible={loading}
      />
      {!loading && (
        <>
          {loggedIn ? (
            <DeDUAppShell
              debug={process.env.NODE_ENV === "development"}
              header={{
                spotlightActions,
              }}
              navbar={{
                items: [
                  <Link key="home" style={{ textDecoration: "none" }} to="/">
                    <DeDUNavLink
                      active={activePath === "/"}
                      icon={<IconHome />}
                      label={t("menu.Home")}
                    />
                  </Link>,
                  <DeDUNavLink
                    key="cases"
                    active={activePath.startsWith("/cases")}
                    defaultOpened={activePath.startsWith("/cases")}
                    icon={<IconList />}
                    label={t("menu.Cases")}
                    variant="subtle"
                  >
                    <Link
                      key="cases.cases"
                      style={{ textDecoration: "none" }}
                      to="/cases"
                    >
                      <DeDUNavLink
                        active={
                          activePath.startsWith("/cases") &&
                          !activePath.startsWith("/cases/mine") &&
                          !activePath.startsWith("/cases/create")
                        }
                        label={t("case.Cases")}
                      />
                    </Link>
                    <Link
                      key="cases.mycases"
                      style={{ textDecoration: "none" }}
                      to="/cases/mine"
                    >
                      <DeDUNavLink
                        active={activePath.startsWith("/cases/mine")}
                        label={t("case.MyCases")}
                      />
                    </Link>
                    <Link
                      key="cases.create"
                      style={{ textDecoration: "none" }}
                      to="/cases/create"
                    >
                      <DeDUNavLink
                        active={activePath.startsWith("/cases/create")}
                        label={t("case.CreateCase")}
                      />
                    </Link>
                  </DeDUNavLink>,
                  <DeDUNavLink
                    key="objectmodel"
                    active={activePath.startsWith("/objectmodel")}
                    defaultOpened={activePath.startsWith("/objectmodel")}
                    icon={<IconBinaryTree2 />}
                    label={t("menu.ObjectModel")}
                    variant="subtle"
                  >
                    <Link
                      key="objectmodel.standard"
                      style={{ textDecoration: "none" }}
                      to="/objectmodel/standard"
                    >
                      <DeDUNavLink
                        active={activePath.startsWith("/objectmodel/standard")}
                        label={t("objectModel.ViewStandard")}
                      />
                    </Link>
                    <Link
                      key="objectmodel.create"
                      style={{ textDecoration: "none" }}
                      to="/objectmodel/create"
                    >
                      <DeDUNavLink
                        active={activePath.startsWith("/objectmodel/create")}
                        label={t("objectModel.CreateItem")}
                      />
                    </Link>
                    <Link
                      key="objectmodel.view"
                      style={{ textDecoration: "none" }}
                      to="/objectmodel/view"
                    >
                      <DeDUNavLink
                        active={activePath.startsWith("/objectmodel/view")}
                        label={t("objectModel.ViewModel")}
                      />
                    </Link>

                    <Link
                      key="objectmodel.groups"
                      style={{ textDecoration: "none" }}
                      to="/objectmodel/groups"
                    >
                      <DeDUNavLink
                        active={activePath.startsWith("/objectmodel/groups")}
                        label={t("objectModel.groups.title")}
                      />
                    </Link>
                  </DeDUNavLink>,
                  <DeDUNavLink
                    key="org"
                    active={activePath.startsWith("/org")}
                    defaultOpened={activePath.startsWith("/org")}
                    icon={<IconUsers />}
                    label={t("menu.Organization")}
                    variant="subtle"
                  >
                    <Link
                      key="org.name"
                      style={{ textDecoration: "none" }}
                      to="/org/name"
                    >
                      <DeDUNavLink
                        active={activePath.startsWith("/org/name")}
                        label={t("common.Name")}
                      />
                    </Link>
                    <Link
                      key="org.roles"
                      style={{ textDecoration: "none" }}
                      to="/org/roles"
                    >
                      <DeDUNavLink
                        active={activePath.startsWith("/org/roles")}
                        label={t("common.ExecutorRoles")}
                      />
                    </Link>
                    <Link
                      key="org.accounts"
                      style={{ textDecoration: "none" }}
                      to="/org/accounts"
                    >
                      <DeDUNavLink
                        active={activePath.startsWith("/org/accounts")}
                        label={t("menu.UserAccounts")}
                      />
                    </Link>
                  </DeDUNavLink>,
                  <Link
                    key="permissions"
                    style={{ textDecoration: "none" }}
                    to="/permissions"
                  >
                    <DeDUNavLink
                      active={activePath.startsWith("/permissions")}
                      icon={<IconLockCog />}
                      label={t("menu.Permissions")}
                    />
                  </Link>,
                ],
                userBadge: {
                  defaultOpen: activePath.startsWith("/user"),
                  items: [
                    <Link
                      key="roles"
                      style={{ textDecoration: "none" }}
                      to="/user/roles"
                    >
                      <DeDUNavLink
                        active={activePath.startsWith("/user/roles")}
                        icon={<IconUserCheck />}
                        label={t("common.ExecutorRoles")}
                      />
                    </Link>,
                    <Link
                      key="area.responsibility"
                      style={{ textDecoration: "none" }}
                      to="/user/area-responsibility"
                    >
                      <DeDUNavLink
                        active={activePath.startsWith(
                          "/user/area-responsibility"
                        )}
                        icon={<IconBuildingCommunity />}
                        label={t("common.AreaResponsibility")}
                      />
                    </Link>,

                    <span
                      key="logout"
                      onClick={() => window.location.replace(stateLogoutUrl)}
                    >
                      <DeDUNavLink
                        icon={<IconLogout2 />}
                        label={t("menu.Logout")}
                      />
                    </span>,
                  ],
                  loading: isLoading,
                  user: {
                    email: data?.customerName || "",
                    imageSrc:
                      "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
                    name: identity.data?.username || "",
                  },
                },
              }}
              notificationsOpened={notificationsOpened}
              opened={opened}
              setNotificationsOpened={setNotificationsOpened}
              setOpened={setOpened}
            >
              {children}
            </DeDUAppShell>
          ) : (
            <DeDUCenter h="100%" pos="fixed" w="100%">
              <DeDUBox
                h={{ base: "100%", sm: "auto" }}
                p="lg"
                sx={theme => ({
                  backgroundColor: "#fff",
                  borderRadius: theme.radius.md,
                })}
                w={{ base: "100%", sm: "auto" }}
              >
                <DeDUGrid columns={2} h="100%">
                  <DeDUGrid.Col span={1}>
                    <DeDUImage
                      alt="WSP"
                      height={38}
                      src="/wsp_logo.svg"
                      width={80}
                    />
                  </DeDUGrid.Col>
                  {process.env.NODE_ENV === "development" ? (
                    <DeDUGrid.Col span={1}>
                      <DeDUText align="end" color="red" size="xs" weight="bold">
                        Debug mode
                      </DeDUText>
                    </DeDUGrid.Col>
                  ) : null}

                  <DeDUGrid.Col span={2}>
                    <DeDUStack align="center" mb="md" spacing="md">
                      <div style={{ textAlign: "center" }}>
                        <DeDUText size="sm" weight="bold">
                          DeDU FuRY
                        </DeDUText>
                        <DeDUText color="gray" size="xs">
                          {t("preScreen.version")}: {packageInfo.version}
                        </DeDUText>
                      </div>
                      <DeDUButton
                        onClick={() => window.location.replace("bff/login")}
                        w={{ base: "80%", sm: 200 }}
                      >
                        {t("preScreen.toLogin")}
                      </DeDUButton>
                    </DeDUStack>
                  </DeDUGrid.Col>
                </DeDUGrid>
              </DeDUBox>
            </DeDUCenter>
          )}
        </>
      )}
    </div>
  )
}
export default MainLayout
