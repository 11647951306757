import { AxiosResponse } from "axios"
import {
  ICreateUser,
  ICreateUserResponse,
  IUser,
} from "common/types/user/user.type"

import http, { handleError } from "./utilities/http-common"
const url = "identities"

class IdentityService {
  getLoggedInUser = (): Promise<IUser> =>
    http
      .get(`/${url}/loggedInUser`)
      .then(response => response.data)
      .catch(error => handleError(error))

  getUsers = (): Promise<IUser[]> =>
    http
      .get(`/${url}/users`)
      .then(response => response.data)
      .catch(error => handleError(error))

  getUser = (userId: string): Promise<IUser> =>
    http
      .get(`/${url}/user/${userId}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  addUser = (data: ICreateUser): Promise<AxiosResponse<ICreateUserResponse>> =>
    http.post(`/${url}`, data).then(response => response)
}

export default new IdentityService()
