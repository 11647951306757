import {
  DeDUCard,
  DeDUCommonList,
  DeDUSelect,
  DeDUText,
} from "@dedu-internal/dedu-ui"
import { useQuery } from "@tanstack/react-query"
import customerService from "common/services/tsApi/customer.service"
import personService from "common/services/tsApi/person.service"
import { IRole } from "common/types/role/role.type"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface IProps {
  onSelect?: (value: string) => void
  useSelect?: boolean
}

interface SelectItem {
  label: string
  value: string
}

const MyExecutors: FC<IProps> = ({ onSelect, useSelect = false }) => {
  const [userRoles, setUserRoles] = useState<SelectItem[]>([])
  const { t } = useTranslation()

  const roles = useQuery(["executor_roles"], () =>
    customerService.getRoles().then(res => (res ? res : []))
  )

  const personRoles = useQuery(["myRoles"], () =>
    personService.getMyRoleIds().then(res => res)
  )

  const filterSelected = () => {
    const selected = new Array<SelectItem>()
    if (personRoles.data?.length) {
      roles.data &&
        roles.data.forEach(role => {
          if (personRoles.data?.includes(role.roleId?.value))
            selected.push(convertToSelectItem(role))
        })
    }

    setUserRoles(selected)
  }

  const convertToSelectItem = (item: IRole) => {
    return {
      label: item.roleName ? item.roleName : "",
      value: item.roleId?.value ? item.roleId.value : "",
    }
  }

  useEffect(() => {
    if (personRoles.data && roles.data?.length) filterSelected()
  }, [personRoles.data, roles.data]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderRoleItem = (role: SelectItem) => {
    return <DeDUText key={role.value}>{role.label}</DeDUText>
  }

  if (personRoles.isError || roles.isError)
    return <DeDUText color="red">{t("common.ErrorMessage")}</DeDUText>

  return (
    <>
      {useSelect ? (
        <DeDUSelect
          data={userRoles}
          isLoading={personRoles.isInitialLoading || roles.isInitialLoading}
          onChange={onSelect}
          placeholder={t("common.Choose") + t("common.ExecutorRole")}
          clearable
        />
      ) : (
        <DeDUCard
          isLoading={personRoles.isInitialLoading || roles.isInitialLoading}
        >
          <DeDUCommonList
            data={userRoles}
            empty={<></>}
            render={renderRoleItem}
          ></DeDUCommonList>
        </DeDUCard>
      )}
    </>
  )
}

export default MyExecutors
