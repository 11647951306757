import {
  DeDUAvatar,
  DeDUCard,
  DeDUCommonList,
  DeDUDivider,
  DeDUFlex,
  DeDUText,
  DeDUTitle,
} from "@dedu-internal/dedu-ui"
import { IconEdit } from "@tabler/icons-react"
import { useQuery } from "@tanstack/react-query"
import identityService from "common/services/tsApi/identity.service"
import { DeduIdentityManagementContractDeDuClaimDto } from "common/types/data-contracts"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const UserList = () => {
  const { t } = useTranslation()
  const usersQuery = useQuery(["users"], () =>
    identityService.getUsers().then(res => res)
  )

  const getName = (
    claims: Array<DeduIdentityManagementContractDeDuClaimDto> | undefined | null
  ) => {
    if (!claims) return ""

    const givenName = claims.find(f => f.key === "given_name")?.value
    const familyName = claims.find(f => f.key === "family_name")?.value

    return givenName + " " + familyName
  }

  return (
    <DeDUCard isLoading={usersQuery.isFetching || usersQuery.isInitialLoading}>
      <DeDUTitle order={5}>{t("common.Existing")}</DeDUTitle>
      <DeDUDivider my="md" />
      <DeDUCommonList
        data={usersQuery.data || []}
        empty={<DeDUText>{t("user.NoUserAccounts")}</DeDUText>}
        isLoading={usersQuery.isFetching || usersQuery.isLoading}
        render={item => (
          <DeDUFlex align="center" justify="space-between">
            <DeDUFlex sx={{ overflow: "hidden" }}>
              <DeDUAvatar
                size="lg"
                styles={{ placeholder: { background: "none" } }}
              />
              <DeDUFlex
                direction="column"
                justify="center"
                sx={{ overflow: "hidden" }}
              >
                <DeDUText size="sm" truncate>
                  {item.email}
                </DeDUText>
                <DeDUText color="dimmed" size="sm" truncate>
                  {getName(item.claims)}
                </DeDUText>
              </DeDUFlex>
            </DeDUFlex>
            <Link to={"/org/accounts/" + item.loginId}>
              <IconEdit color="black" stroke={1.5} />
            </Link>
          </DeDUFlex>
        )}
      />
    </DeDUCard>
  )
}

export default UserList
