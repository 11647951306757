import { DeDUGrid, DeDUPage } from "@dedu-internal/dedu-ui"
import { useTranslation } from "react-i18next"

import UserForm from "./users/UserForm"
import UserList from "./users/UserList"

const UserAccounts = () => {
  const { t } = useTranslation()

  return (
    <DeDUPage
      containerProps={{ fluid: true }}
      title={t("menu.UserAccounts") || ""}
    >
      <DeDUGrid gutter="md">
        <DeDUGrid.Col md={6} span={12}>
          <UserForm />
        </DeDUGrid.Col>
        <DeDUGrid.Col md={6} span={12}>
          <UserList />
        </DeDUGrid.Col>
      </DeDUGrid>
    </DeDUPage>
  )
}

export default UserAccounts
