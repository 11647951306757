import {
  DeDUActionIcon,
  DeDUCard,
  DeDUCommonList,
  DeDUDivider,
  DeDUFlex,
  DeDUGrid,
  DeDUPage,
  DeDUText,
  DeDUTitle,
  DeDUTreeView,
} from "@dedu-internal/dedu-ui"
import { IconBinaryTree2 } from "@tabler/icons-react"
import { useQuery } from "@tanstack/react-query"
import objectModelService from "common/services/tsApi/objectModel.service"
import { IObjectModel } from "common/types/objectModel/objectModel.type"
import { convertMonotoringsToTreeItems } from "common/utils/objectModel.utils"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const SeeAreaResponsibility = () => {
  const { t } = useTranslation()
  const [selectedMonitoring, setSelectedMonitoring] = useState<IObjectModel>()

  const objectModel = useQuery(["objectModel"], () =>
    objectModelService.getObjectModels().then(res => res || [])
  )

  const monitorings = useQuery(
    ["myCorrectiveMonitorings"],
    () =>
      objectModelService
        .getUserMonitorings(objectModel?.data?.[0]?.modelId || "")
        .then(res => res || []),
    {
      enabled: !!objectModel.data, //wait for objectModelId
    }
  )

  return (
    <DeDUPage
      containerProps={{ fluid: true }}
      title={t("user.MyAreaResponsibilities") || ""}
    >
      <DeDUGrid>
        <DeDUGrid.Col md={6} span={12}>
          <DeDUCard>
            <DeDUCommonList
              data={monitorings.data || []}
              isLoading={monitorings.isFetching || objectModel.isFetching}
              render={item => (
                <DeDUFlex align="center" justify="space-between">
                  <DeDUText>{item?.name}</DeDUText>
                  <DeDUActionIcon
                    data-testid="select-monitoring-button"
                    onClick={_ => setSelectedMonitoring(item)}
                  >
                    <IconBinaryTree2 color="black" stroke={1.5} />
                  </DeDUActionIcon>
                </DeDUFlex>
              )}
            />
          </DeDUCard>
        </DeDUGrid.Col>

        <DeDUGrid.Col md={6} span={12}>
          {selectedMonitoring && (
            <DeDUCard>
              <DeDUTitle order={5}>{selectedMonitoring.name}</DeDUTitle>
              <DeDUDivider my="sm" />
              <DeDUTreeView
                data={convertMonotoringsToTreeItems(selectedMonitoring)}
              />
            </DeDUCard>
          )}
        </DeDUGrid.Col>
      </DeDUGrid>
    </DeDUPage>
  )
}

export default SeeAreaResponsibility
