import {
  DeDUAccordion,
  DeDUBox,
  DeDUCard,
  DeDUCheckBox,
  DeDUGroup,
  DeDUMediaQuery,
  DeDUPage,
  DeDUStack,
  DeDUTable,
  DeDUText,
  DeDUTitle,
} from "@dedu-internal/dedu-ui"
import { IconAdjustmentsHorizontal, IconBlockquote } from "@tabler/icons-react"
import { useQuery } from "@tanstack/react-query"
import caseService from "common/services/tsApi/case.service"
import { ICase } from "common/types/case/case.type"
import StatusText from "common/utils/StatusText"
import { FC, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

const ListAllCases: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [showCompleted, setShowCompleted] = useState(false)

  const cases = useQuery<ICase[]>(["cases"], () =>
    caseService.getCases().then(res => res || [])
  )

  const filteredCases = useMemo(() => {
    return cases.data?.filter(caseItem => {
      if (caseItem.status !== 3 || showCompleted) {
        return true
      }

      return false
    })
  }, [cases.data, showCompleted])

  return (
    <DeDUPage containerProps={{ fluid: true }} title={t("menu.Cases")}>
      <DeDUBox pt="md">
        <DeDUMediaQuery smallerThan="xs" styles={{ display: "none" }}>
          <DeDUCard isLoading={cases.isLoading}>
            <DeDUTable
              sx={theme => ({
                fontSize: theme.fn.smallerThan("sm") ? 6 : "inherit",
              })}
              verticalSpacing="sm"
              highlightOnHover
            >
              <thead>
                <tr>
                  <th>{t("case.Case")}</th>
                  <th>{t("case.CaseDescription")}</th>
                  {/* <th>{t("common.Location")}</th> */}
                  <th>{t("common.Status")}</th>
                  <th>
                    <DeDUCheckBox
                      checked={showCompleted}
                      label={
                        <DeDUMediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <DeDUText>{t("case.displayClosed")}</DeDUText>
                        </DeDUMediaQuery>
                      }
                      onChange={event =>
                        setShowCompleted(event.currentTarget.checked)
                      }
                      sx={{ whiteSpace: "nowrap" }}
                      title={t("case.displayClosed")}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredCases &&
                  filteredCases
                    .slice()
                    .reverse()
                    .map(item => (
                      <tr
                        key={item.caseId?.value}
                        onClick={() =>
                          history.push(`/cases/${item.caseId?.value}`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <th>{item.caseNumber?.formattedNumber}</th>
                        <td>{item.description}</td>
                        {/* <td>{item.modelItemInfo?.modelItemId}</td> */}
                        <td>
                          <StatusText status={item?.status} />
                        </td>
                        <td></td>
                      </tr>
                    ))}
              </tbody>
            </DeDUTable>
          </DeDUCard>
        </DeDUMediaQuery>

        <DeDUMediaQuery largerThan="xs" styles={{ display: "none" }}>
          <DeDUBox>
            {filteredCases && (
              <DeDUStack>
                <DeDUAccordion variant="contained">
                  <DeDUAccordion.Item value="filter">
                    <DeDUAccordion.Control icon={<IconAdjustmentsHorizontal />}>
                      <DeDUText size="sm">{t("common.filter")}</DeDUText>
                    </DeDUAccordion.Control>
                    <DeDUAccordion.Panel>
                      <DeDUCheckBox
                        checked={showCompleted}
                        label={t("case.displayClosed")}
                        onChange={event =>
                          setShowCompleted(event.currentTarget.checked)
                        }
                        title={t("case.displayClosed")}
                      />
                    </DeDUAccordion.Panel>
                  </DeDUAccordion.Item>
                </DeDUAccordion>
                {filteredCases
                  .slice()
                  .reverse()
                  .map(item => (
                    <div
                      key={item.caseId?.value}
                      onClick={() =>
                        history.push(`/cases/${item.caseId?.value}`)
                      }
                    >
                      <DeDUCard key={item.caseId?.value} shadow="sm">
                        <div style={{ position: "absolute", right: "2em" }}>
                          <DeDUText size="sm" ta="right">
                            <StatusText status={item?.status} />
                          </DeDUText>
                        </div>

                        <DeDUTitle mb={6} order={4}>
                          {item.caseNumber?.formattedNumber}
                        </DeDUTitle>

                        <DeDUGroup mb={6}>
                          <IconBlockquote />
                          <DeDUText truncate>{item.description}</DeDUText>
                        </DeDUGroup>

                        {/* <DeDUGroup>
                          <IconMapPin />
                          {item.modelItemInfo?.modelItemId}
                        </DeDUGroup> */}
                      </DeDUCard>
                    </div>
                  ))}
              </DeDUStack>
            )}
          </DeDUBox>
        </DeDUMediaQuery>
      </DeDUBox>
    </DeDUPage>
  )
}

export default ListAllCases
