import "./common/services/language/i18n/i18n"

import { DeduLoadingOverlay } from "@dedu-internal/dedu-ui"
import React from "react"
import ReactDOM from "react-dom"
import { Router } from "react-router-dom"
import App from "setup/App"

//import { unregister } from './registerServiceWorker';
import history from "./common/history"

const rootElement = document.getElementById("root")

ReactDOM.render(
  <Router history={history}>
    <React.Suspense fallback={<DeduLoadingOverlay visible={true} />}>
      <App />
    </React.Suspense>
  </Router>,
  rootElement
)

//unregister();
