import {
  DeDUCard,
  DeDUDivider,
  DeDUForm,
  DeDUText,
  DeDUTreeSelectItem,
} from "@dedu-internal/dedu-ui"
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import objectModelService from "common/services/tsApi/objectModel.service"
import {
  ICreateObjectModelScope,
  IObjectModelItem,
} from "common/types/objectModel/objectModel.type"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

interface IProps {
  objectModelId?: string
}

type GroupForm = Omit<ICreateObjectModelScope, "objectModelId">

const CreateGroup: FC<IProps> = ({ objectModelId }) => {
  const { t } = useTranslation()
  const invalidateTimeout = useRef<NodeJS.Timeout>()
  const [treeSelectItems, setTreeSelectItems] = useState<DeDUTreeSelectItem[]>(
    []
  )
  const queryClient = useQueryClient()

  useEffect(() => {
    return () => window.clearTimeout(invalidateTimeout.current)
  }, [])

  const convertModelItemsToFormItems = useCallback(
    (modelItems: IObjectModelItem[]) => {
      return modelItems.map(item => ({
        label: item.itemName || "",
        value: item.modelItemId || "",
      }))
    },
    []
  )

  const { isLoading } = useQuery(
    ["objectItems"],
    () =>
      objectModelService
        .getObjectModelItems(objectModelId!.split("@")[0]) // eslint-disable-line @typescript-eslint/no-non-null-assertion
        .then(res => res),
    {
      enabled: !!objectModelId, // wait for objectModelId
      onSuccess: (data: IObjectModelItem[]) =>
        setTreeSelectItems(convertModelItemsToFormItems(data)),
    }
  )

  const results = useQueries({
    queries: treeSelectItems.map(selectItem => {
      return {
        enabled: !!treeSelectItems.length && !!objectModelId,
        onSuccess: (data: IObjectModelItem[]) =>
          setTreeSelectItems(curr =>
            curr.map(c =>
              c.value === selectItem.value
                ? { ...c, children: convertModelItemsToFormItems(data) }
                : c
            )
          ),
        queryFn: () =>
          objectModelService
            .getObjectModelItemsUnderParent(
              objectModelId!.split("@")[0], // eslint-disable-line @typescript-eslint/no-non-null-assertion
              selectItem.value
            )
            .then(res => res),
        queryKey: ["objectItemsUnderParent", selectItem.value],
      }
    }),
  })

  const isLoadingChildren = useMemo(() => {
    return results.some(result => result.isLoading)
  }, [results])

  const mutation = useMutation({
    mutationFn: (values: ICreateObjectModelScope) => {
      return objectModelService.createObjectModelScope(values)
    },
  })

  const submitGroup = (values: GroupForm) => {
    const group: ICreateObjectModelScope = {
      items: values.items,
      name: values.name,
      objectModelId: objectModelId || "",
    }

    return mutation.mutateAsync(group, {
      onSuccess: () => {
        invalidateTimeout.current = setTimeout(() => {
          queryClient.invalidateQueries(["objectModelGroups"])
        }, 1000)
      },
    })
  }

  return (
    <DeDUCard
      isLoading={isLoading || isLoadingChildren}
      sx={{ overflow: "visible" }}
    >
      <DeDUText>{t("objectModel.CreateGroup")}</DeDUText>
      <DeDUDivider my="sm" />

      <DeDUForm<GroupForm>
        clearButton={{ label: t("common.Clear") }}
        items={[
          {
            key: "name",
            label: t("common.Name"),
            placeholder: t("objectModel.groups.createGroup.namePlaceholder"),
            schema: Yup.string()
              .min(2, t("objectModel.groups.createGroup.nameRequired"))
              .required(t("objectModel.groups.createGroup.nameRequired")),
            type: "text",
            value: "",
          },
          {
            key: "items",
            label: t("objectModel.groups.createGroup.modelItems"),
            multiple: true,
            placeholder: t(
              "objectModel.groups.createGroup.modelItemsPlaceholder"
            ),
            schema: Yup.array()
              .of(Yup.string().required())
              .min(1, t("objectModel.groups.createGroup.modelItemsRequired")),
            treeSelectItems: treeSelectItems,
            type: "treeselect",
            value: [],
          },
        ]}
        submit={{
          clearAfterSubmit: true,
          onSubmit: submitGroup,
          resolveErrorMessage: () => t("objectModel.error.createGroupError"),
          resolveSuccessMessage: () =>
            t("objectModel.groups.createGroup.successfullyAdded"),
        }}
        submitButton={{ label: t("common.Create") }}
      />
    </DeDUCard>
  )
}

export default CreateGroup
