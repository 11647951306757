import { AxiosResponse } from "axios"
import { ICase, ICreateCase, IUpdateCase } from "common/types/case/case.type"

import http, { handleError } from "./utilities/http-common"
const url = "cases"

class CaseService {
  getCases = (): Promise<ICase[]> =>
    http
      .get(`/${url}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  getCase = (caseId: string): Promise<ICase> =>
    http
      .get(`/${url}/${caseId}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  createCase = (data: ICreateCase): Promise<AxiosResponse> =>
    http.post(`/${url}`, data).then(response => response)

  updateCase = (data: IUpdateCase): Promise<AxiosResponse> =>
    http.patch(`/${url}`, data).then(response => response)
}

export default new CaseService()
