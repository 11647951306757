import {
  DeDUBox,
  DeDUCard,
  DeDUCommonList,
  DeDUDivider,
  DeDUForm,
  DeDUGrid,
  DeDUPage,
  DeDUText,
  DeDUTitle,
} from "@dedu-internal/dedu-ui"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import customerService from "common/services/tsApi/customer.service"
import { IAddRole } from "common/types/role/role.type"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

const OrganizationRoles = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { data, isFetching, isInitialLoading } = useQuery({
    onError: (err: AxiosError) => err,
    queryFn: customerService.getRoles,
    queryKey: ["executor_roles"],
  })

  const mutation = useMutation({
    mutationFn: (values: IAddRole) => {
      return customerService.addRole(values)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["executor_roles"])
    },
  })

  const submitRole = (values: IAddRole) => {
    return mutation.mutateAsync(values)
  }

  return (
    <DeDUPage
      containerProps={{ fluid: true }}
      title={t("common.ExecutorRoles") || ""}
    >
      <DeDUGrid gutter="md">
        <DeDUGrid.Col md={6} span={12}>
          <DeDUCard>
            <DeDUTitle mb={2} order={5}>
              {t("common.New")}
            </DeDUTitle>
            <DeDUDivider my="md" />
            <DeDUForm<IAddRole>
              items={[
                {
                  key: "executorRoleName",
                  label: t("common.ExecutorRole"),
                  schema: Yup.string()
                    .min(
                      2,
                      t("common.Error.LengthMinError", {
                        key: t("common.ExecutorRole"),
                        n: 2,
                      })
                    )
                    .max(
                      20,
                      t("common.Error.LengthMaxError", {
                        key: t("common.ExecutorRole"),
                        n: 20,
                      })
                    )
                    .required(
                      t("common.Error.RequiredField", {
                        key: t("common.ExecutorRole"),
                      })
                    ),
                  type: "text",
                  value: "",
                },
              ]}
              submit={{
                clearAfterSubmit: true,
                onSubmit: submitRole,
                resolveErrorMessage: () => t("common.ErrorMessage"),
                resolveSuccessMessage: () =>
                  t("organization.ExecutorRoleAdded"),
              }}
              submitButton={{ label: t("common.Add") }}
            />
          </DeDUCard>
        </DeDUGrid.Col>
        <DeDUGrid.Col md={6} span={12}>
          <DeDUCard isLoading={isInitialLoading}>
            <DeDUTitle mb={2} order={5}>
              {t("common.Existing")}
            </DeDUTitle>
            <DeDUDivider my="md" />

            <DeDUCommonList
              data={data || []}
              data-testid="dedu-list"
              empty={<DeDUText>{t("organization.NoExecutorRoles")}</DeDUText>}
              isLoading={isInitialLoading || isFetching}
              render={item => (
                <DeDUBox key={item.roleId?.value}>{item.roleName}</DeDUBox>
              )}
            />
          </DeDUCard>
        </DeDUGrid.Col>
      </DeDUGrid>
    </DeDUPage>
  )
}

export default OrganizationRoles
