import { AxiosResponse } from "axios"
import { ISetPersonRoles } from "common/types/person/personRoles.type"
import { IRoleId } from "common/types/role/role.type"

import http, { handleError } from "./utilities/http-common"
const url = "persons"

class PersonService {
  getRoleIdsForPerson = (personId: string): Promise<IRoleId[]> =>
    http
      .get(`/${url}/roles/${personId}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  getMyRoleIds = (): Promise<IRoleId[]> =>
    http
      .get(`/${url}/myroles`)
      .then(response => response.data)
      .catch(error => handleError(error))

  setUserRoles = (
    personId: string,
    data: ISetPersonRoles
  ): Promise<AxiosResponse> =>
    http.post(`/${url}/roles/${personId}`, data).then(response => response)
}

export default new PersonService()
