import {
  DeDUAccordion,
  DeDUCard,
  DeDUCheckBox,
  DeDUDivider,
  DeDUGroup,
  DeDUMediaQuery,
  DeDUSkeleton,
  DeDUStack,
  DeDUTable,
  DeDUText,
  DeDUTitle,
} from "@dedu-internal/dedu-ui"
import { IconAdjustmentsHorizontal, IconBlockquote } from "@tabler/icons-react"
import { IGetCaseMonitoring } from "common/types/objectModel/objectModel.type"
import StatusText from "common/utils/StatusText"
import { ChangeEvent, FC, Fragment, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

interface IProps {
  loading: boolean
  monitoredCases: IGetCaseMonitoring[] | undefined
}

const MyCaseList: FC<IProps> = ({ loading, monitoredCases }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [completedCasesToShow, setCompletedCasesToShow] = useState<string[]>([])

  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    itemId: string
  ) => {
    if (event.currentTarget.checked) {
      setCompletedCasesToShow(curr => [...curr, itemId])
    } else {
      setCompletedCasesToShow(curr => curr.filter(c => c !== itemId))
    }
  }

  const getFilteredMonitoredCases = useCallback(
    (
      cases: NonNullable<IGetCaseMonitoring["monitoredCases"]>,
      shouldDisplayClosed: boolean
    ) => {
      return cases?.filter(caseItem => {
        if (caseItem.caseData?.status !== 3 || shouldDisplayClosed) {
          return true
        }

        return false
      })
    },
    []
  )

  return (
    <>
      {loading ? (
        <DeDUStack>
          {Array.from(Array(4).keys()).map((_, i) => (
            <DeDUSkeleton key={i} height={40} width="100%" />
          ))}
        </DeDUStack>
      ) : (
        <DeDUStack>
          {monitoredCases &&
            monitoredCases.map(item => (
              <Fragment key={item.id}>
                <DeDUMediaQuery smallerThan="xs" styles={{ display: "none" }}>
                  <DeDUCard key={item.id}>
                    <DeDUTitle order={4}>{item.name}</DeDUTitle>
                    <DeDUTable verticalSpacing="sm" highlightOnHover>
                      <thead>
                        <tr>
                          <th>{t("case.Case")}</th>
                          <th>{t("case.CaseDescription")}</th>
                          {/* <th>{t("common.Location")}</th> */}
                          <th>{t("common.Status")}</th>
                          <th>
                            <DeDUCheckBox
                              checked={completedCasesToShow.includes(
                                item.id || ""
                              )}
                              label={
                                <DeDUMediaQuery
                                  smallerThan="md"
                                  styles={{ display: "none" }}
                                >
                                  <DeDUText>{t("case.displayClosed")}</DeDUText>
                                </DeDUMediaQuery>
                              }
                              onChange={event =>
                                handleOnChange(event, item.id || "")
                              }
                              sx={{ whiteSpace: "nowrap" }}
                              title={t("case.displayClosed")}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.monitoredCases &&
                          getFilteredMonitoredCases(
                            item.monitoredCases,
                            completedCasesToShow.includes(item.id || "")
                          )
                            .reverse()
                            .map(item => (
                              <Fragment key={item.caseData?.id?.value}>
                                <tr
                                  onClick={() =>
                                    history.push(
                                      `/cases/${item.caseData?.id?.value}`
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <th>{item.caseData?.caseNumber}</th>
                                  <td>{item.caseData?.description}</td>
                                  {/* <td>{item.caseData?.targetItemName}</td> */}
                                  <td>
                                    <StatusText
                                      status={item.caseData?.status}
                                    />
                                  </td>
                                  <td />
                                </tr>
                              </Fragment>
                            ))}
                      </tbody>
                    </DeDUTable>
                  </DeDUCard>
                </DeDUMediaQuery>

                <DeDUMediaQuery largerThan="xs" styles={{ display: "none" }}>
                  <DeDUStack>
                    {item.monitoredCases && (
                      <>
                        <DeDUDivider
                          label={item.name}
                          labelPosition="center"
                          size="sm"
                        />
                        <DeDUAccordion variant="contained">
                          <DeDUAccordion.Item value={item.name || ""}>
                            <DeDUAccordion.Control
                              icon={<IconAdjustmentsHorizontal />}
                            >
                              <DeDUText size="sm">
                                {t("common.filter")}
                              </DeDUText>
                            </DeDUAccordion.Control>
                            <DeDUAccordion.Panel>
                              <DeDUCheckBox
                                checked={completedCasesToShow.includes(
                                  item.id || ""
                                )}
                                label={t("case.displayClosed")}
                                onChange={event =>
                                  handleOnChange(event, item.id || "")
                                }
                                title={t("case.displayClosed")}
                              />
                            </DeDUAccordion.Panel>
                          </DeDUAccordion.Item>
                        </DeDUAccordion>
                        {getFilteredMonitoredCases(
                          item.monitoredCases,
                          completedCasesToShow.includes(item.id || "")
                        )
                          .reverse()
                          .map(item => (
                            <div
                              key={item.caseData?.id?.value}
                              onClick={() =>
                                history.push(
                                  `/cases/${item.caseData?.id?.value}`
                                )
                              }
                            >
                              <DeDUCard shadow="sm">
                                <div
                                  style={{ position: "absolute", right: "2em" }}
                                >
                                  <DeDUText size="sm" ta="right">
                                    <StatusText
                                      status={item.caseData?.status}
                                    />
                                  </DeDUText>
                                </div>

                                <DeDUTitle mb={6} order={4}>
                                  {item.caseData?.caseNumber}
                                </DeDUTitle>

                                <DeDUGroup mb={6}>
                                  <IconBlockquote />
                                  <DeDUText truncate>
                                    {item.caseData?.description}
                                  </DeDUText>
                                </DeDUGroup>

                                {/* <DeDUGroup mb={6}>
                                  <IconMapPin />
                                  {item.caseData?.targetItemName}
                                </DeDUGroup> */}
                              </DeDUCard>
                            </div>
                          ))}
                      </>
                    )}
                  </DeDUStack>
                </DeDUMediaQuery>
              </Fragment>
            ))}
        </DeDUStack>
      )}
    </>
  )
}

export default MyCaseList
