import {
  DeDUButton,
  DeDUCard,
  DeDUForm,
  DeDUGrid,
  DeDUGroup,
  DeDUPage,
  DeDUSkeleton,
  DeDUStack,
  DeDUText,
  DeDUTitle,
} from "@dedu-internal/dedu-ui"
import { IconChevronLeft } from "@tabler/icons-react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import caseService from "common/services/tsApi/case.service"
import customerService from "common/services/tsApi/customer.service"
import objectModelService from "common/services/tsApi/objectModel.service"
import workOrderService from "common/services/tsApi/workOrder.service"
import { ICase, IUpdateCase } from "common/types/case/case.type"
import { IObjectModelItemPath } from "common/types/objectModel/objectModel.type"
import {
  IGetWorkOrderByCase,
  IUpdateExecutorRole,
} from "common/types/workOrder/workOrder.type"
import { DeDUStatus } from "common/utils/DeDUStatus"
import StatusText from "common/utils/StatusText"
import { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { StaleTime } from "setup/StaleTime"
import * as Yup from "yup"

import CaseComments from "./CaseComments"

interface MatchParams {
  id: string
}

type ExtendedUpdateCase = IUpdateCase & {
  executorRoleId: string
  workOrderId: string
}

const CaseDetails: FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const queryClient = useQueryClient()
  const [location, setLocation] = useState<string>()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const caseId = useMemo(() => {
    return match.params.id
  }, [match.params.id])

  const caseDetails = useQuery<ICase>(["case", caseId], () =>
    caseService.getCase(caseId).then(res => res)
  )

  const workOrders = useQuery<IGetWorkOrderByCase[]>(
    ["workorders", caseId],
    () => workOrderService.getWorkOrderOnCaseId(caseId).then(res => res),
    {
      enabled: !!caseId,
      staleTime: StaleTime.Short,
    }
  )

  const executorRoles = useQuery(
    ["executor_roles"],
    () => customerService.getRoles().then(res => res || []),
    {
      enabled: !!workOrders.data,
    }
  )

  const modelItemPath = useQuery<IObjectModelItemPath[]>(
    ["model_item_path", caseDetails.data?.modelItemInfo?.modelItemId],
    () =>
      objectModelService
        .getModelItemPaths(
          caseDetails.data?.modelItemInfo?.modelId ?? "",
          caseDetails.data?.modelItemInfo?.modelItemId ?? ""
        )
        .then(res => res),
    {
      enabled: !!caseDetails.data?.modelItemInfo,
    }
  )

  useEffect(() => {
    if (modelItemPath.data) {
      let path = ""
      if (modelItemPath.data[0]) {
        const itemPath = modelItemPath.data[0].itemPath
        if (itemPath && itemPath?.length > 1) {
          path = itemPath[1].headerData.name
          path += " / " + itemPath[0].headerData.name
        } else if (itemPath) {
          path = itemPath[0].headerData.name
        }
      }
      setLocation(path)
    }
  }, [modelItemPath.data])

  const caseMutation = useMutation({
    mutationFn: (updateCase: IUpdateCase) => {
      return caseService.updateCase(updateCase)
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(["case", caseId])
        queryClient.invalidateQueries(["monitoredcases"])
        queryClient.invalidateQueries(["cases"])
      }, 1000)
    },
  })

  const executorRoleMutation = useMutation({
    mutationFn: (updateRole: IUpdateExecutorRole) => {
      return workOrderService.updateExecutorRole(updateRole)
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(["workorders", caseId])
        queryClient.invalidateQueries(["monitoredcases"])
        queryClient.invalidateQueries(["cases"])
      }, 1000)
    },
  })

  const submitCase = async (values: ExtendedUpdateCase) => {
    executorRoleMutation.mutate({
      caseId: caseId,
      executorRoleId: values.executorRoleId,
      workOrderId: workOrders.data && workOrders.data[0].workOrderId,
    } as IUpdateExecutorRole)

    return caseMutation.mutateAsync({ ...values, caseId })
  }

  if (caseDetails.isError)
    return <DeDUText color="red">{t("common.ErrorMessage")}</DeDUText>

  return (
    <DeDUPage
      containerProps={{ fluid: true }}
      title={
        t("case.Case") +
        " " +
        (caseDetails.data?.caseNumber
          ? caseDetails.data?.caseNumber?.sequenceNumber
          : "")
      }
    >
      <DeDUButton
        bg="transparent"
        onClick={history.goBack}
        p={0}
        variant="subtle"
      >
        <IconChevronLeft />
        {t("menu.GoBack")}
      </DeDUButton>

      <DeDUGrid columns={2} grow>
        <DeDUGrid.Col md={1}>
          <DeDUCard
            isLoading={
              caseDetails.isInitialLoading ||
              executorRoles.isInitialLoading ||
              workOrders.isInitialLoading
            }
          >
            <DeDUStack>
              <DeDUGroup>
                <DeDUTitle order={5}>{t("common.Status")}</DeDUTitle>
                <DeDUText>
                  <StatusText status={caseDetails.data?.status} />
                </DeDUText>
              </DeDUGroup>

              <DeDUGroup>
                <DeDUTitle order={5}>{t("common.Location")}</DeDUTitle>
                <DeDUText>
                  {modelItemPath.isInitialLoading ? (
                    <DeDUSkeleton height={20} width={200} />
                  ) : (
                    <>{location}</>
                  )}
                </DeDUText>
              </DeDUGroup>

              <DeDUForm<ExtendedUpdateCase>
                items={[
                  {
                    disabled:
                      caseDetails.data &&
                      caseDetails.data.status === DeDUStatus.Closed,
                    key: "description",
                    label: t("case.CaseDescription"),
                    schema: Yup.string()
                      .min(2, t("case.Error.DescriptionLength"))
                      .required(
                        t("common.Error.RequiredField", {
                          key: t("case.Description"),
                        })
                      ),
                    type: "textarea",
                    value: caseDetails.data?.description || "",
                  },
                  {
                    disabled:
                      caseDetails.data &&
                      caseDetails.data.status === DeDUStatus.Closed,
                    isLoading:
                      executorRoles.isInitialLoading ||
                      workOrders.isInitialLoading,
                    key: "executorRoleId",
                    label: t("common.ExecutorRole"),
                    options: executorRoles.data?.map(item => ({
                      label: item.roleName,
                      value: item.roleId?.value,
                    })) as { label: string; value: string }[],
                    schema: Yup.string().required(),
                    type: "select",
                    value:
                      (workOrders.data &&
                        workOrders.data[0].executorRoleId?.value) ||
                      "",
                  },
                ]}
                submit={{ onSubmit: submitCase }}
                submitButton={{ label: t("common.Save") }}
              />
            </DeDUStack>
          </DeDUCard>

          <DeDUCard isLoading={caseDetails.isInitialLoading} my="lg">
            <DeDUStack>
              <DeDUTitle order={2}>{t("case.Notifier")}</DeDUTitle>

              <DeDUGroup>
                <DeDUTitle order={5}>{t("common.Name")}</DeDUTitle>
                <DeDUText>
                  {caseDetails.data?.notifierInfo?.firstName}{" "}
                  {caseDetails.data?.notifierInfo?.lastName}
                </DeDUText>
              </DeDUGroup>

              <DeDUGroup>
                <DeDUTitle order={5}>{t("common.EmailAddress")}</DeDUTitle>
                <DeDUText>
                  {caseDetails.data?.notifierInfo?.emailAddress}
                </DeDUText>
              </DeDUGroup>

              <DeDUGroup>
                <DeDUTitle order={5}>{t("common.Phone")}</DeDUTitle>
                <DeDUText>
                  {caseDetails.data?.notifierInfo?.phoneNumber}
                </DeDUText>
              </DeDUGroup>
            </DeDUStack>
          </DeDUCard>
        </DeDUGrid.Col>
        <DeDUGrid.Col md={1}>
          <DeDUCard isLoading={caseDetails.isInitialLoading}>
            <DeDUStack>
              {workOrders.data && (
                <CaseComments
                  caseClosed={
                    caseDetails.data &&
                    caseDetails.data.status === DeDUStatus.Closed
                  }
                  caseId={caseId}
                  comments={workOrders.data[0].comments || []}
                  workOrderId={workOrders.data[0].workOrderId || ""}
                />
              )}
            </DeDUStack>
          </DeDUCard>
        </DeDUGrid.Col>
        <DeDUGrid.Col md={1}></DeDUGrid.Col>
      </DeDUGrid>
    </DeDUPage>
  )
}

export default CaseDetails
