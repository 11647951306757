import { Col, Divider, Row, Space, Spin } from "antd"
import objectModelService from "common/services/tsApi/objectModel.service"
import ObjectModelForm from "pages/objectmodel/components/ObjectModelForm"
import ObjectModelSelect from "pages/objectmodel/components/ObjectModelSelect"
import ObjectModelTemplateDropDown from "pages/objectmodel/components/ObjectModelTemplateDropDown"
import ObjectModelTree from "pages/objectmodel/components/ObjectModelTree"
import { useEffect, useState } from "react"

const ModelItemTree = () => {
  const [objectModels, setObjectModels] = useState([])
  const [objectModelsLoading, setObjectModelsLoading] = useState(true)
  const [treeStateObject, setTreeStateObject] = useState({
    selectedObjectModelId: null,
    showAttribute: false,
    showContextMenu: true,
  })
  const [modalFormShown, setModalFormShown] = useState({
    isNew: null,
    node: null,
    visible: false,
  })
  const [objectModelTemplateMap, setObjectModelTemplateMap] = useState(
    new Map()
  )
  const [selectedObjectModelTemplate, setSelectedObjectModelTemplate] =
    useState({ objectModelId: null, onShowModelForm: null, template: null })

  const [saving, setSaving] = useState(false)

  // This method fetches all ObjectModels and Templates of the logged in customer
  const getObjectsTemplates = () => {
    let objectModelArray = null

    objectModelService
      .getObjectModels()
      .then(data => {
        var modelData = data.modelData != null ? data.modelData : []
        console.log("Object Models:", modelData)
        objectModelArray = modelData.map(
          ({ modelIdField, name, templateIdField }) => ({
            id: modelIdField.split("@")[0],
            objectModel_name: name,
            template_Id: templateIdField,
          })
        )

        //objectModelService.getTemplates()
        //    .then(data => {
        //        console.log("Templates", data);

        //        var tempObjectTemplateMap = new Map();
        //        if (objectModelArray !== null && objectModelArray !== undefined) {
        //            objectModelArray.map(o => {
        //                if (!tempObjectTemplateMap.has(o.id)) {
        //                    tempObjectTemplateMap.set(o.id, data.find(({ id }) => id === o.template_Id));
        //                }
        //            });
        //        }
        //        console.log(tempObjectTemplateMap);
        //        setObjectModelTemplateMap(tempObjectTemplateMap);

        //    });

        setObjectModels(objectModelArray)
      })
      .finally(() => {
        setObjectModelsLoading(false)
      })
  }

  const showObjectModelForm = obj => {
    setModalFormShown(obj)
  }

  const onObjectModelSelected = id => {
    var templateForSelectedObjectModel = objectModelTemplateMap.get(id)
    setTreeStateObject({
      onShowModelForm: showObjectModelForm,
      selectedObjectModelId: id,
      showAttribute: false,
      showContextMenu: true,
      template: templateForSelectedObjectModel,
    })
    setSelectedObjectModelTemplate({
      objectModelId: id,
      onShowModelForm: showObjectModelForm,
      template: templateForSelectedObjectModel,
    })
  }

  const onAddModelItem = () => {
    setModalFormShown({ isNew: true, visible: true })
  }

  const onAddModelFormSubmit = (formObj, isNew) => {
    //setModalFormShown({ visible: false });
    //setSaving(true);
    //const modelItemJson = { 'isNew': isNew, 'formObj': formObj };
    //console.log("Save obj", modelItemJson);
    //if (isNew) {
    //    apiObjectModel.post(JSON.stringify(modelItemJson), "/modelItem").then(rsp => {
    //        console.log("Values submitted", modelItemJson, rsp);
    //    }).finally(() => {
    //        setSaving(false);
    //    });
    //} else {
    //    apiObjectModel.put(JSON.stringify(modelItemJson), "/modelItem").then(rsp => {
    //        console.log("Values submitted", modelItemJson, rsp);
    //    }).finally(() => {
    //        setSaving(false);
    //    });
    //}
  }

  const onAddModelFormCancel = () => {
    setModalFormShown({ visible: false })
  }

  useEffect(() => {
    getObjectsTemplates()
  }, [])

  return (
    <div>
      <h3>Model Items</h3>
      <Divider />
      <Space direction="vertical" size="middle">
        <Spin spinning={objectModelsLoading}>
          <Row gutter={{ lg: 32, md: 24, sm: 16, xs: 8 }}>
            <Col span={12}>
              <ObjectModelSelect
                objectModels={objectModels}
                onSelected={onObjectModelSelected}
              />
            </Col>
            <Col span={12}>
              <ObjectModelTemplateDropDown {...selectedObjectModelTemplate} />
            </Col>
          </Row>
          <ObjectModelForm
            onCancel={onAddModelFormCancel}
            onSubmit={onAddModelFormSubmit}
            {...modalFormShown}
          />
        </Spin>
        <Spin spinning={saving}>
          <ObjectModelTree {...treeStateObject} />
        </Spin>
      </Space>
    </div>
  )
}

export default ModelItemTree
