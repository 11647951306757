import {
  DeDUCard,
  DeDUCommonList,
  DeDUFlex,
  DeDUForm,
  DeDUGroup,
  DeDUScrollArea,
  DeDUText,
} from "@dedu-internal/dedu-ui"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import workOrderService from "common/services/tsApi/workOrder.service"
import {
  ICloseWorkOrder,
  ICreateComment,
  IGetWorkOrderByCase,
} from "common/types/workOrder/workOrder.type"
import { format, parseISO } from "date-fns"
import { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

type Comment = NonNullable<IGetWorkOrderByCase["comments"]>[number]

type IParams = {
  caseClosed: boolean | undefined
  caseId: string
  comments: Comment[]
  workOrderId: string | undefined
}

type INewComment = {
  comment: string
  endTask: boolean
  isExternal: boolean
}

const CaseComments: FC<IParams> = ({ ...params }) => {
  const [comments, setComments] = useState<Comment[]>([])

  const queryClient = useQueryClient()
  const viewport = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const formatDate = (date: string) => {
    if (!date) {
      return
    }
    const parsedDate = parseISO(date)
    return format(parsedDate, "yyyy-MM-dd HH:mm")
  }

  const scrollToBottom = () => {
    viewport?.current?.scrollTo({
      behavior: "smooth",
      top: viewport.current.scrollHeight,
    })
  }

  const submitComment = async (values: INewComment) => {
    if (values.endTask) {
      return closeWorkOrderMutation.mutateAsync({
        caseId: params.caseId,
        comment: values.comment,
        workOrderId: params.workOrderId,
      } as ICloseWorkOrder)
    } else {
      return commentMutation.mutateAsync({
        caseId: params.caseId,
        comment: values.comment,
      } as ICreateComment)
    }
  }
  const closeWorkOrderMutation = useMutation({
    mutationFn: (closeWorkOrder: ICloseWorkOrder) => {
      return workOrderService.closeWorkOrder(closeWorkOrder)
    },
    onSuccess: (_, variables) => {
      setTimeout(() => {
        addComment(variables.comment)
        queryClient.invalidateQueries(["case", params.caseId])
        queryClient.invalidateQueries(["monitoredcases"])
        queryClient.invalidateQueries(["cases"])
      }, 1000)
    },
  })

  const commentMutation = useMutation({
    mutationFn: (addComment: ICreateComment) => {
      return workOrderService.createComment(addComment)
    },
    onSuccess: (_, variables) => {
      addComment(variables.comment)
    },
  })

  const addComment = (comment: string) => {
    const now = format(new Date(), "yyyy-MM-dd HH:mm")
    setComments([...comments, { date: now, text: comment as string }])
  }

  useEffect(() => {
    if (params.comments) {
      setComments(params.comments)
    }
  }, [params.comments])

  useEffect(() => {
    scrollToBottom()
  }, [comments])

  return (
    <>
      <DeDUScrollArea
        h={320}
        p="lg"
        scrollbarSize={10}
        sx={{ border: "1px solid #ccc", borderRadius: "10px" }}
        viewportRef={viewport}
      >
        {params.comments && (
          <DeDUCommonList
            data={comments}
            empty={<DeDUText color="grey">{t("case.NoComments")}</DeDUText>}
            itemSpacing="lg"
            render={(
              item: NonNullable<IGetWorkOrderByCase["comments"]>[number]
            ) => (
              <DeDUCard>
                <DeDUFlex justify="space-between">
                  <DeDUText color="gray">
                    {`${formatDate(item.date || "")}`}
                  </DeDUText>
                  {/* Will be hardcoded for now since we dont know yet how to display this */}
                  <DeDUText color="grey">(Intern kommentar)</DeDUText>
                </DeDUFlex>
                <DeDUText>{item.text}</DeDUText>
                <DeDUGroup mt="sm" position="right">
                  {/* TODO: replace this when we have actual "modifiedBy" */}
                  <DeDUText>Steven McQueen</DeDUText>
                </DeDUGroup>
              </DeDUCard>
            )}
          />
        )}
      </DeDUScrollArea>

      {!params.caseClosed && (
        <DeDUForm<INewComment>
          items={[
            {
              key: "comment",
              label: t("case.Comment"),
              schema: Yup.string()
                .min(2, t("case.Error.DescriptionLength"))
                .required(
                  t("case.Error.CommentLength", {
                    key: t("case.Description"),
                  })
                ),
              type: "textarea",
              value: "",
            },
            {
              disabled: true,
              key: "isExternal",
              label: "Extern kommentar",
              schema: Yup.boolean().default(false).notRequired(),
              type: "checkbox",
              value: false,
            },
            {
              disabled: false,
              key: "endTask",
              label: t("case.EndTask"),
              schema: Yup.boolean().default(false).notRequired(),
              type: "checkbox",
              value: false,
            },
          ]}
          submit={{
            clearAfterSubmit: true,
            onSubmit: submitComment,
            resolveErrorMessage: () => t("common.ErrorMessage"),
          }}
          submitButton={{ label: t("common.Submit") }}
        />
      )}
    </>
  )
}

export default CaseComments
