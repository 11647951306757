import { DeDUCard, DeDUGrid } from "@dedu-internal/dedu-ui"
import { useQuery } from "@tanstack/react-query"
import { ItemTree } from "common/itemTree/ItemTree"
import objectModelService from "common/services/tsApi/objectModel.service"
import {
  IObjectModel,
  IObjectModelItem,
} from "common/types/objectModel/objectModel.type"
import { FC, useState } from "react"

import ModelItemAttributeView from "./ModelItemAttributeView"

type IProps = { selectedObjectModel: IObjectModel }

const ObjectModelTree: FC<IProps> = ({ selectedObjectModel }) => {
  const objectModelId = selectedObjectModel?.modelId?.split("@")[0]
  const [selectedModelItem, setSelectedModelItem] = useState<IObjectModelItem>()
  const [modelItems, setModelItems] = useState<IObjectModelItem[]>([])

  const { isInitialLoading, isLoading } = useQuery({
    onSuccess: data => setModelItems(data ?? []),
    queryFn: () =>
      objectModelService
        .getObjectModelItems(objectModelId ?? "")
        .then(res => res),
    queryKey: [`objectModelItems-${objectModelId}`],
  })

  return (
    <DeDUGrid columns={3} grow>
      <DeDUGrid.Col md={1}>
        <DeDUCard isLoading={isInitialLoading || isLoading}>
          <ItemTree
            modelItems={modelItems}
            objectModelId={objectModelId}
            onSelect={(_, { node }) =>
              setSelectedModelItem(
                modelItems.find(item => item.modelItemId === node.key)
              )
            }
            setModelItems={setModelItems}
          />
        </DeDUCard>
      </DeDUGrid.Col>
      <DeDUGrid.Col md={2}>
        <DeDUCard isLoading={isInitialLoading || isLoading}>
          <ModelItemAttributeView modelItem={selectedModelItem} />
        </DeDUCard>
      </DeDUGrid.Col>
    </DeDUGrid>
  )
}

export default ObjectModelTree
