/*/*Until we figure out way to use C# enums from contract, a copy enum here*/
export enum CustomerCommandServicePermissionFlags {
  None = 0,
  CanCreateCustomer = 1,
  CanDeleteCustomer = 1 << 1,
  CanUpdateCustomer = 1 << 2,
  All = ~None,
}

export enum CustomerQueryServicePermissionFlags {
  None = 0,
  CanReadCustomer = 1,
  All = ~None,
}

export const customerCmdName = "CustomerCommandClient"
export const customerQueryName = "CustomerQueryClient"
