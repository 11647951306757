import { AxiosResponse } from "axios"
import {
  ICustomer,
  IUpdateCustomer,
  IValidateCustomerName,
  IValidateCustomerNameResponse,
} from "common/types/customer/customer.type"
import { IAddRole, IRole } from "common/types/role/role.type"

import http, { handleError } from "./utilities/http-common"
const url = "customer"

class CustomerService {
  getCustomer = (): Promise<ICustomer> =>
    http
      .get(`/${url}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  getRoles = (): Promise<IRole[]> =>
    http
      .get(`/${url}/roles`)
      .then(response => response.data)
      .catch(error => handleError(error))

  validateName = (
    data: IValidateCustomerName
  ): Promise<IValidateCustomerNameResponse> =>
    http.post(`/${url}/validate`, data).then(response => response.data)

  addRole = (data: IAddRole): Promise<AxiosResponse> =>
    http.post(`/${url}/role`, data).then(response => response)

  updateName = (data: IUpdateCustomer): Promise<AxiosResponse> =>
    http.patch(`/${url}`, data).then(response => response)
}

export default new CustomerService()
