import "./ObjectTreeContextMenu.css"

import { DeDUDivider, DeDUGrid, DeDUText } from "@dedu-internal/dedu-ui"
import { IObjectModelItem } from "common/types/objectModel/objectModel.type"
import { FC, Fragment, useMemo } from "react"
import { useTranslation } from "react-i18next"

interface IProps {
  modelItem: IObjectModelItem | undefined
}

const ModelItemAttributeView: FC<IProps> = props => {
  const { t } = useTranslation()

  const properties = useMemo(() => {
    if (props.modelItem?.itemJson) {
      const itemProps = JSON.parse(props.modelItem.itemJson)["properties"]
      return Object.keys(itemProps).reduce((acc, key) => {
        const value = itemProps[key]

        if (!key.includes("$") && !key.includes("model_id")) {
          acc[key] = value[0]
        }

        return acc
      }, {} as { [key: string]: { value: string } })
    }

    return {}
  }, [props.modelItem])

  const getTitle = () => {
    if (props.modelItem) {
      return `${t("common.AttributesFor")} ${props.modelItem.itemName}`
    }
    return t("objectModel.chooseAnyObject")
  }

  return (
    <>
      <DeDUText>{getTitle()}</DeDUText>
      {props.modelItem?.itemJson && (
        <>
          <DeDUDivider mb="sm" mt="xs" />
          <DeDUGrid columns={5} justify="space-between">
            <DeDUGrid.Col md={2.5} pt="xs" sx={{ overflow: "hidden" }}>
              <DeDUText size="xs" weight="bold" ellipsis>
                Type
              </DeDUText>
            </DeDUGrid.Col>
            <DeDUGrid.Col md={2.5} pt={{ base: 0, md: "xs" }}>
              <DeDUText size="xs">{props.modelItem.label}</DeDUText>
            </DeDUGrid.Col>

            {Object.entries(properties).map(([key, value]) => (
              <Fragment key={key}>
                <DeDUGrid.Col md={2.5} pt="xs" sx={{ overflow: "hidden" }}>
                  <DeDUText size="xs" weight="bold" ellipsis>
                    {key}
                  </DeDUText>
                </DeDUGrid.Col>
                <DeDUGrid.Col md={2.5} pt={{ base: 0, md: "xs" }}>
                  <DeDUText size="xs">{value.value}</DeDUText>
                </DeDUGrid.Col>
              </Fragment>
            ))}
          </DeDUGrid>
        </>
      )}
    </>
  )
}

export default ModelItemAttributeView
