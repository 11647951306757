import { Modal } from "antd"
import { Divider } from "antd"
import { useEffect, useState } from "react"

import Field from "./Field"
import FieldGroup from "./FieldGroup"

const ObjectModelForm = props => {
  const [currentFormData, setCurrentFormData] = useState({
    activities: [],
    id: "",
    parentId: "",
    props: [],
    type: "",
  })
  const [values, setValues] = useState({})

  const fieldChanged = (fieldId, value) => {
    setValues(currentValues => {
      currentValues[fieldId] = value
      return currentValues
    })

    setCurrentFormData(currentFrmData => {
      return Object.assign({}, currentFrmData)
    })
  }

  const updateTemplateObjValues = () => {
    if (values !== null && currentFormData !== null) {
      let nameProp = ""
      currentFormData.props.forEach(f => {
        if (f.fields !== undefined && f.fields.length > 0) {
          for (const subField of f.fields) {
            subField.value = values[subField.id]
          }
        } else {
          f.value = values[f.id]
          if (f.label === "name") {
            nameProp = f.value
          }
        }
      })

      currentFormData.label = nameProp
    }
  }

  const handleSubmit = event => {
    updateTemplateObjValues()

    event.preventDefault()
    if (props.onSubmit) {
      currentFormData.props = currentFormData.props.filter(
        p => p.label !== "_meta"
      )
      props.onSubmit(currentFormData, props.isNew)
    }
  }

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }

  useEffect(() => {
    if (props.payload) {
      const templateObj = JSON.parse(props.payload.modelItemJson)

      setValues(currentValues => {
        const newValues = templateObj.props.reduce((obj, field) => {
          if (field.fields !== undefined && field.fields.length > 0) {
            for (const subField of field.fields) {
              obj[subField.id] = props.isNew ? "" : subField.value
            }
          } else {
            obj[field.id] =
              props.isNew && field.label !== "model_id" ? "" : field.value
          }

          return obj
        }, {})

        return Object.assign({}, newValues, currentValues)
      })

      setCurrentFormData(templateObj)
    } else {
      //setCurrentFormData({ id: "", type: "", props: [] });
      setValues({})
    }
  }, [props.payload])

  const getFormLabel = () => {
    if (props.isNew) {
      return `Add New ${currentFormData.type}`
    } else {
      return `Edit ${currentFormData.label}`
    }
  }

  const filterFormData = () => {
    return currentFormData.props.filter(
      p => p.label !== "_meta" && p.label !== "model_id"
    )
  }

  return (
    <Modal
      destroyOnClose={true}
      footer={null}
      onCancel={handleCancel}
      title={getFormLabel()}
      visible={props.visible}
    >
      <form onSubmit={handleSubmit}>
        {filterFormData().map(f => {
          if (f.fields !== undefined && f.fields.length > 0) {
            return (
              <FieldGroup
                key={f.id}
                field={f}
                fieldChanged={fieldChanged}
                showBlank={props.isNew}
              />
            )
          } else {
            return (
              <Field
                key={f.id}
                field={f}
                fieldChanged={fieldChanged}
                showBlank={props.isNew}
                value={f.value}
              />
            )
          }
        })}
        <Divider />
        <input type="submit" value="Submit" />
      </form>
    </Modal>
  )
}

export default ObjectModelForm
