import { DeDUPage, DeDUSelect, DeDUStack } from "@dedu-internal/dedu-ui"
import { useQuery } from "@tanstack/react-query"
import objectModelService from "common/services/tsApi/objectModel.service"
import { ComponentProps, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import ObjectModelTree from "./components/ObjectModelTree"

const ViewObjectModel = () => {
  const { t } = useTranslation()
  const [selectedObjectModelId, setSelectedObjectModelId] = useState<
    string | null
  >(null)

  const { data, isInitialLoading, isLoading } = useQuery({
    onSuccess: data => setSelectedObjectModelId(data[0]?.modelId ?? null),
    queryFn: objectModelService.getObjectModels,
    queryKey: ["objectModels"],
  })

  const selectedObjectModel = useMemo(() => {
    return data?.find(model => model.modelId === selectedObjectModelId)
  }, [data, selectedObjectModelId])

  return (
    <DeDUPage
      containerProps={{ fluid: true }}
      title={t("objectModel.ViewModel")}
    >
      <DeDUStack>
        <DeDUSelect
          data={
            data?.map(om => {
              return {
                label: om.name || "",
                value: om.modelId,
              } as ComponentProps<typeof DeDUSelect>["data"][number]
            }) || []
          }
          isLoading={isLoading || isInitialLoading}
          label={t("objectModel.chooseObjectModel")}
          onChange={setSelectedObjectModelId}
          sx={{ width: "50%" }}
          value={selectedObjectModelId}
          searchable
        />

        {selectedObjectModel && (
          <ObjectModelTree selectedObjectModel={selectedObjectModel} />
        )}
      </DeDUStack>
    </DeDUPage>
  )
}

export default ViewObjectModel
