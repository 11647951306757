import { DeDUTreeSelectItem } from "@dedu-internal/dedu-ui"
import { useQueries } from "@tanstack/react-query"
import objectModelService from "common/services/tsApi/objectModel.service"
import {
  IObjectModelItem,
  IObjectModelMonitoring,
} from "common/types/objectModel/objectModel.type"
import { Dispatch, SetStateAction, useMemo } from "react"

export const convertModelItemsToFormItems = (
  modelItems: IObjectModelItem[]
) => {
  return modelItems.map(item => ({
    label: item.itemName || "",
    value: item.modelItemId || "",
  }))
}

export const useObjectModelItemsUnderParent = (
  treeSelectItems: DeDUTreeSelectItem[],
  objectModelId: string | null,
  setTreeSelectItems: Dispatch<SetStateAction<DeDUTreeSelectItem[]>>
) => {
  const results = useQueries({
    queries: treeSelectItems.map(parentItem => ({
      enabled: !!objectModelId && !!parentItem.value,
      onSuccess: (data: IObjectModelItem[]) => {
        setTreeSelectItems((curr: DeDUTreeSelectItem[]) =>
          curr.map(c =>
            c.value === parentItem.value
              ? { ...c, children: convertModelItemsToFormItems(data) }
              : c
          )
        )
      },
      queryFn: () =>
        objectModelService
          .getObjectModelItemsUnderParent(
            objectModelId! /* eslint-disable-line @typescript-eslint/no-non-null-assertion */,
            parentItem.value
          )
          .then(res => res),
      queryKey: ["objectModelItemsUnderParent", parentItem.value],
    })),
  })

  const isModelItemsUnderParentLoading = useMemo(() => {
    return results.some(result => result.isLoading)
  }, [results])

  return {
    isModelItemsUnderParentLoading,
  }
}

const convertChildren = (children: IObjectModelItem[]) => {
  return children.map(child => {
    return { label: child.itemName, value: child.modelItemId }
  })
}

export const convertMonotoringsToTreeItems = (
  monotoring: IObjectModelMonitoring
) => {
  if (!monotoring.referencedItems?.length) {
    return []
  }

  const items = monotoring.referencedItems.map(item => {
    return {
      children: convertChildren(item?.childItemData || []),
      label: item.refItemData?.itemName || "",
      value: item.refItemData?.modelItemId || "",
    } as DeDUTreeSelectItem
  })

  return items
}
