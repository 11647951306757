import { DeDUAccordion, DeDUButton, DeDUPage } from "@dedu-internal/dedu-ui"
import { useQuery } from "@tanstack/react-query"
import PermissionService from "common/services/tsApi/permission.service"
import { useTranslation } from "react-i18next"

const UserPermissions = () => {
  const { t } = useTranslation()
  const { data, error, isFetching, isInitialLoading, refetch } = useQuery(
    ["permissions"],
    () => PermissionService.getPermissions().then(res => res)
  )

  if (error) return <>An error has occurred</>

  return (
    <DeDUPage containerProps={{ fluid: true }} title={t("menu.Permissions")}>
      <DeDUAccordion
        chevronPosition="left"
        isLoading={isFetching || isInitialLoading}
        mb="md"
        variant="contained"
      >
        {data &&
          Object.entries(data).map(([key, values]) => (
            <DeDUAccordion.Item key={key} value={key}>
              <DeDUAccordion.Control>{key}</DeDUAccordion.Control>
              <DeDUAccordion.Panel>{values}</DeDUAccordion.Panel>
            </DeDUAccordion.Item>
          ))}
      </DeDUAccordion>

      <DeDUButton onClick={() => refetch()}>{t("common.Refresh")}</DeDUButton>
    </DeDUPage>
  )
}

export default UserPermissions
