import {
  DeDUCard,
  DeDUDivider,
  DeDUForm,
  DeDUGrid,
  DeDUGroup,
  DeDUPage,
  DeDUStack,
} from "@dedu-internal/dedu-ui"
import { IconBuildingEstate, IconHome2 } from "@tabler/icons-react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import objectModelService from "common/services/tsApi/objectModel.service"
import {
  ICreateObjectModelItem,
  IObjectModel,
} from "common/types/objectModel/objectModel.type"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

const CreateItem = () => {
  const { t } = useTranslation()
  const [objectModels, setObjectModels] = useState<IObjectModel[]>()
  const [selectedObjectModelId, setSelectedObjectModelId] =
    useState<IObjectModel["modelId"]>()
  const [loadingObjectModels, setLoadingObjectModels] = useState(false)
  const queryClient = useQueryClient()

  const getObjectModels = () => {
    setLoadingObjectModels(true)
    objectModelService
      .getObjectModels()
      .then(rsp => {
        if (rsp.length) {
          setObjectModels(rsp)
          setSelectedObjectModelId(rsp[0].modelId)
        }
      })
      .catch(error => {
        console.log(error, "There was an error") //eslint-disable-line no-console
      })
      .finally(() => {
        setLoadingObjectModels(false)
      })
  }

  const modelItems = useQuery(
    ["parentItems"],
    () =>
      objectModelService
        .getObjectModelItems(selectedObjectModelId?.split("@")[0] ?? "")
        .then(res => res),
    {
      enabled: !!selectedObjectModelId, //wait for objectModelId
    }
  )

  useEffect(() => {
    getObjectModels()
  }, [])

  const mutation = useMutation({
    mutationFn: (modelItem: ICreateObjectModelItem) => {
      return objectModelService.createObjectModelItem(modelItem)
    },
    onSuccess: _ => {
      setTimeout(() => {
        queryClient.invalidateQueries(["parentItems"])
      }, 1500)
    },
  })

  const submitModelItem = async (values: ICreateObjectModelItem) => {
    values.objectModelId = selectedObjectModelId ?? ""
    // values.parentId = "6d5fe9e7-2a1e-41cc-8472-fb3754e76242"
    return mutation.mutateAsync(values)
  }

  return (
    <DeDUPage
      containerProps={{ fluid: true }}
      title={t("objectModel.CreateItem") || ""}
    >
      <DeDUGrid columns={2}>
        <DeDUGrid.Col md={1}>
          <DeDUCard isLoading={loadingObjectModels}>
            <DeDUStack>
              <DeDUGroup>
                <IconBuildingEstate />
                Fastighet
              </DeDUGroup>
              <DeDUCard.Section>
                <DeDUDivider />
              </DeDUCard.Section>
              <DeDUForm<ICreateObjectModelItem>
                items={[
                  {
                    key: "name",
                    label: t("common.Name"),
                    placeholder: "Namn på ny fastighet",
                    schema: Yup.string()
                      .min(2, t("case.Error.DescriptionLength"))
                      .required(
                        t("case.Error.CommentLength", {
                          key: t("case.Description"),
                        })
                      ),
                    type: "text",
                    value: "",
                  },
                ]}
                submit={{
                  clearAfterSubmit: true,
                  onSubmit: submitModelItem,
                  resolveErrorMessage: () => t("common.ErrorMessage"),
                  resolveSuccessMessage: () => "Fastighet har skapats!",
                }}
                submitButton={{ label: t("common.Create") }}
              />
            </DeDUStack>
          </DeDUCard>
        </DeDUGrid.Col>

        <DeDUGrid.Col md={1}>
          <DeDUCard isLoading={loadingObjectModels}>
            <DeDUStack>
              <DeDUGroup>
                <IconHome2 />
                Byggnad
              </DeDUGroup>
              <DeDUCard.Section>
                <DeDUDivider />
              </DeDUCard.Section>
              <DeDUForm<ICreateObjectModelItem>
                items={[
                  {
                    isLoading: modelItems.isInitialLoading,
                    key: "parentId",
                    label: "Fastighet",
                    options:
                      modelItems.data &&
                      (modelItems.data.map(item => ({
                        label: item.itemName,
                        value: item.modelItemId,
                      })) as { label: string; value: string }[]),
                    placeholder: "Välj fastighet",
                    schema: Yup.string().required("Fastighet måste väljas"),
                    type: "select",
                    value: "",
                  },
                  {
                    key: "name",
                    label: t("common.Name"),
                    placeholder: "Namn på ny byggnad",
                    schema: Yup.string()
                      .min(2, t("case.Error.DescriptionLength"))
                      .required(
                        t("case.Error.CommentLength", {
                          key: t("case.Description"),
                        })
                      ),
                    type: "text",
                    value: "",
                  },
                ]}
                submit={{
                  clearAfterSubmit: true,
                  onSubmit: submitModelItem,
                  resolveErrorMessage: () => t("common.ErrorMessage"),
                  resolveSuccessMessage: () => "Byggnad har skapats!",
                }}
                submitButton={{ label: t("common.Create") }}
              />
            </DeDUStack>
          </DeDUCard>
        </DeDUGrid.Col>
      </DeDUGrid>
    </DeDUPage>
  )
}

export default CreateItem
