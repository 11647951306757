import axios, { AxiosError } from "axios"

export default axios.create({
  baseURL: "/api",
  headers: {
    "Content-type": "application/json",
    "X-CSRF": 1,
  },
})

export function handleError(error: AxiosError) {
  if (error.response?.status === 401) {
    console.error("HTTP response 401", error) // eslint-disable-line no-console

    // Will not redirect if already on the login page
    if (window.location.pathname !== "/") {
      console.log("Redirecting to login page") // eslint-disable-line no-console
      return window.location.replace("/")
    }
  }

  throw error
}
