import { ICase } from "common/types/case/case.type"
import { FC, useCallback } from "react"
import { useTranslation } from "react-i18next"

import { DeDUStatus } from "./DeDUStatus"

interface IProps {
  status?: ICase["status"]
}

const StatusText: FC<IProps> = ({ status }) => {
  const { t } = useTranslation()

  const getStatusText = useCallback(
    (status: ICase["status"]) => {
      switch (status) {
        case DeDUStatus.Open:
          return t("case.Registered")
        case DeDUStatus.Ongoing:
          return t("case.InProgress")
        case DeDUStatus.Closed:
          return t("case.Closed")
        default:
          return ""
      }
    },
    [t]
  )

  return <>{getStatusText(status)}</>
}

export default StatusText
