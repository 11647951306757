import { DeDUBox, DeDUPage } from "@dedu-internal/dedu-ui"
import { FC } from "react"
import { useTranslation } from "react-i18next"

import MyExecutors from "./components/MyExecutors"

const UserExecutorRoles: FC = () => {
  const { t } = useTranslation()

  return (
    <DeDUPage containerProps={{ fluid: true }} title={t("user.MyRoles")}>
      <DeDUBox maw={600}>
        <MyExecutors />
      </DeDUBox>
    </DeDUPage>
  )
}

export default UserExecutorRoles
