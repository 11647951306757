import {
  DeDUBox,
  DeDUButton,
  DeDUCard,
  DeDUCommonList,
  DeDUForm,
  DeDUGrid,
  DeDUPage,
  DeDUStack,
  DeDUText,
  DeDUTitle,
} from "@dedu-internal/dedu-ui"
import { IconChevronLeft } from "@tabler/icons-react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import customerService from "common/services/tsApi/customer.service"
import identityService from "common/services/tsApi/identity.service"
import personService from "common/services/tsApi/person.service"
import {
  DeduCustomerContractExecutorRoleDataDto,
  DeduIdentityManagementContractDeDuClaimDto,
} from "common/types/data-contracts"
import { ISetPersonRoles } from "common/types/person/personRoles.type"
import { FC, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps, useHistory } from "react-router-dom"
import * as Yup from "yup"

import AreaResponsibility from "./users/AreaResponsibility"

interface IMatchParams {
  id: string
}

type SetRoles = Omit<ISetPersonRoles, "occTag">

const UserDetails: FC<RouteComponentProps<IMatchParams>> = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const [selectableRoles, setSelectableRoles] = useState<
    DeduCustomerContractExecutorRoleDataDto[]
  >([])
  const [userRoles, setUserRoles] = useState<
    DeduCustomerContractExecutorRoleDataDto[]
  >([])
  const queryClient = useQueryClient()

  const identity = useQuery({
    onError: (err: AxiosError) => err,
    queryFn: () =>
      identityService.getUser(props?.match?.params.id).then(res => res),
    queryKey: ["identity", props.match?.params?.id],
  })

  const person = useQuery(["personRoles", props.match?.params?.id], () =>
    personService.getRoleIdsForPerson(props?.match?.params.id).then(res => res)
  )
  const roles = useQuery(["executor_roles"], () =>
    customerService.getRoles().then(res => (res ? res : []))
  )

  const mutation = useMutation({
    mutationFn: (values: ISetPersonRoles) => {
      return personService.setUserRoles(props.match?.params?.id, values)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["personRoles", props.match?.params?.id])
    },
  })

  const onSubmit = (values: SetRoles) => {
    return mutation.mutateAsync({ ...values, occTag: person.data?.[0] })
  }

  const getName = (
    claims: DeduIdentityManagementContractDeDuClaimDto[] | undefined
  ) => {
    if (!claims) return null

    const givenClaim = claims.find(f => f?.key === "given_name")
    const familyClaim = claims.find(f => f?.key === "family_name")

    const given = givenClaim?.value ?? ""
    const family = familyClaim?.value ?? ""

    return given + " " + family
  }

  const filterNotSelected = useCallback(() => {
    const selected: DeduCustomerContractExecutorRoleDataDto[] = []

    if (roles.data) {
      if (person.data?.length) {
        roles.data.forEach(role => {
          if (role.roleId?.value && person.data.includes(role.roleId.value))
            selected.push(role)
        })

        const notSelected = roles.data
          ? roles.data.filter(el => !selected.includes(el))
          : []
        setSelectableRoles(notSelected)
      } else {
        setSelectableRoles(roles.data)
      }
    }

    setUserRoles(selected)
  }, [person.data, roles.data])

  useEffect(() => {
    if (person.data && roles.data?.length) filterNotSelected()
  }, [person.data, roles.data, filterNotSelected])

  if (identity.error)
    return <div>{"An error has occurred: " + identity.error.message}</div>

  return (
    <DeDUPage containerProps={{ fluid: true }} title={t("user.User") || ""}>
      <DeDUButton
        bg="transparent"
        onClick={history.goBack}
        p={0}
        variant="subtle"
      >
        <IconChevronLeft />
        {t("menu.GoBack")}
      </DeDUButton>
      <DeDUGrid>
        <DeDUGrid.Col md={6} span={12}>
          <DeDUCard sx={{ overflow: "visible" }}>
            <DeDUStack>
              <DeDUBox>
                <DeDUTitle order={5}>{t("common.EmailAddress")}</DeDUTitle>
                <DeDUText>{identity.data?.email}</DeDUText>
              </DeDUBox>

              <DeDUBox>
                <DeDUTitle order={5}>{t("common.Name")}</DeDUTitle>
                <DeDUText>
                  {getName(identity.data?.claims || undefined)}
                </DeDUText>
              </DeDUBox>
              <DeDUBox>
                <DeDUTitle order={5}>{t("common.Permission")}</DeDUTitle>
                <DeDUText>{identity.data?.permissionIds}</DeDUText>
              </DeDUBox>

              <DeDUBox>
                <DeDUTitle order={5}>{t("common.ExecutorRoles")}</DeDUTitle>
                <DeDUBox
                  p="xs"
                  sx={{ border: "1px solid #ccc", borderRadius: "10px" }}
                >
                  <DeDUCommonList
                    data={userRoles}
                    render={item => <DeDUText>{item.roleName}</DeDUText>}
                  />
                </DeDUBox>
              </DeDUBox>

              <DeDUForm<SetRoles>
                items={[
                  {
                    key: "executorRoles",
                    label: `${t("common.Add")} ${t("common.ExecutorRole")}`,
                    options: selectableRoles.map(
                      (item: DeduCustomerContractExecutorRoleDataDto) => ({
                        label: item.roleName,
                        value: item.roleId?.value,
                      })
                    ) as { label: string; value: string }[],
                    schema: Yup.array().of(Yup.string()),
                    type: "multiselect",
                    value: [],
                  },
                ]}
                submit={{
                  clearAfterSubmit: true,
                  onSubmit: onSubmit,
                  resolveErrorMessage: () => t("common.ErrorMessage"),
                }}
                submitButton={{ label: t("common.Add") }}
              />
            </DeDUStack>
          </DeDUCard>
        </DeDUGrid.Col>

        <DeDUGrid.Col md={6} span={12}>
          {userRoles.length > 0 && (
            <AreaResponsibility
              personId={props.match?.params?.id}
              userRoles={userRoles}
            />
          )}
        </DeDUGrid.Col>
      </DeDUGrid>
    </DeDUPage>
  )
}

export default UserDetails
