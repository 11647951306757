import {
  DeDUCard,
  DeDUDivider,
  DeDUForm,
  DeDUTitle,
} from "@dedu-internal/dedu-ui"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import customerService from "common/services/tsApi/customer.service"
import identityService from "common/services/tsApi/identity.service"
import { ICreateUser } from "common/types/user/user.type"
import { useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

const UserForm = () => {
  const { t } = useTranslation()
  //Hack to get the emailaddress to resolveSuccessMessage, useState does not work
  const addedUsernameRef = useRef<string>()
  const queryClient = useQueryClient()

  const rolesQuery = useQuery(["executor_roles"], () =>
    customerService.getRoles().then(res => res)
  )

  const accountMutation = useMutation({
    mutationFn: (addUser: ICreateUser) => {
      return identityService.addUser(addUser)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["users"])
    },
  })

  const onSubmit = (values: ICreateUser) => {
    addedUsernameRef.current = values.emailAddress
    return accountMutation.mutateAsync(values)
  }

  const mappedRoles = useMemo(() => {
    if (rolesQuery.data) {
      return rolesQuery.data
        .filter(role => !!role.roleId?.value && !!role.roleName)
        .map(role => {
          return { label: role.roleName, value: role.roleId?.value } as {
            label: string
            value: string
          }
        })
    }
    return []
  }, [rolesQuery.data])

  return (
    <DeDUCard>
      <DeDUTitle order={5}>{t("user.NewAccount")}</DeDUTitle>
      <DeDUDivider my="md" />
      <DeDUForm<ICreateUser>
        items={[
          {
            key: "firstName",
            label: t("user.FirstName"),
            schema: Yup.string()
              .min(2, t("organization.NameRequired"))
              .required(t("organization.NameRequired")),
            type: "text",
            value: "",
          },
          {
            key: "lastName",
            label: t("user.LastName"),
            schema: Yup.string()
              .min(2, t("organization.NameRequired"))
              .required(t("organization.NameRequired")),
            type: "text",
            value: "",
          },
          {
            key: "emailAddress",
            label: t("common.EmailAddress"),
            schema: Yup.string()
              .email(t("common.IncorrectEmail"))
              .required(t("common.IncorrectEmail")),
            type: "text",
            value: "",
          },
          {
            key: "executors",
            label: t("common.ExecutorRoles"),
            options: mappedRoles,
            schema: Yup.string().required(
              t("common.Error.RequiredField", { key: t("common.ExecutorRole") })
            ),
            type: "select",
            value: "",
          },
        ]}
        submit={{
          clearAfterSubmit: true,
          onSubmit: onSubmit,
          resolveSuccessMessage: response => {
            return `${t("user.Username")}: ${addedUsernameRef.current} ${t(
              "common.Password"
            )}: ${response.data.passwordField}`
          },
        }}
        submitButton={{ label: t("common.Add") }}
      />
    </DeDUCard>
  )
}

export default UserForm
