import "./App.css"

import { DeDUThemeProvider, DeDUTitle } from "@dedu-internal/dedu-ui"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { StateProvider } from "common/utils/store.jsx"
import CaseDetails from "pages/correctivemaintenance/case/CaseDetails"
import CaseList from "pages/correctivemaintenance/case/CaseList"
import CreateCase from "pages/correctivemaintenance/case/CreateCase"
import ListAllCases from "pages/correctivemaintenance/case/ListAllCases"
import Home from "pages/Home"
import CreateItem from "pages/objectmodel/CreateItem"
import ModelItemTree from "pages/objectmodel/ModelItemTree"
import ObjectModelGroups from "pages/objectmodel/ObjectModelGroups"
import ViewObjectModel from "pages/objectmodel/ViewObjectModel"
import ViewStandard from "pages/objectmodel/ViewStandard"
import OrganizationName from "pages/organization/OrganizationName"
import OrganizationRoles from "pages/organization/OrganizationRoles"
import UserAccounts from "pages/organization/UserAccounts"
import UserDetails from "pages/organization/UserDetails"
import UserPermissions from "pages/permissions/UserPermissions"
import SeeAreaResponsibility from "pages/user/SeeAreaResponsibility"
import UserExecutorRoles from "pages/user/UserExecutorRoles"
import { Component } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Route, Switch } from "react-router-dom"
import MainLayout from "setup/MainLayout"

import { StaleTime } from "./StaleTime"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: process.env.NODE_ENV === "production",
      retry: process.env.NODE_ENV === "production",
      staleTime: StaleTime.Medium,
    },
  },
})

export default class App extends Component {
  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <StateProvider>
          <DeDUThemeProvider>
            <MainLayout>
              {/*<React.StrictMode>*/}

              <ErrorBoundary
                fallback={<DeDUTitle order={3}>Något gick snett</DeDUTitle>}
                onError={err => console.error(err)} // eslint-disable-line no-console
              >
                <Switch>
                  <Route component={Home} path="/" exact />
                  <Route
                    component={ModelItemTree}
                    path="/objectmodel/tree"
                    exact
                  />
                  <Route
                    component={ViewStandard}
                    path="/objectmodel/standard"
                    exact
                  />
                  <Route
                    component={CreateItem}
                    path="/objectmodel/create"
                    exact
                  />
                  <Route
                    component={ViewObjectModel}
                    path="/objectmodel/view"
                    exact
                  />
                  <Route
                    component={ObjectModelGroups}
                    path="/objectmodel/groups"
                    exact
                  />

                  <Route component={ListAllCases} path="/cases" exact />
                  <Route component={CreateCase} path="/cases/create/" exact />

                  <Route component={CaseList} path="/cases/mine/" exact />
                  <Route component={CaseDetails} path="/cases/:id" exact />

                  <Route
                    component={UserPermissions}
                    path="/permissions"
                    exact
                  />

                  <Route component={OrganizationName} path="/org/name" exact />
                  <Route
                    component={OrganizationRoles}
                    path="/org/roles"
                    exact
                  />
                  <Route component={UserAccounts} path="/org/accounts" exact />
                  <Route
                    component={UserDetails}
                    path="/org/accounts/:id"
                    exact
                  />

                  <Route
                    component={SeeAreaResponsibility}
                    path="/user/area-responsibility"
                    exact
                  />
                  <Route
                    component={UserExecutorRoles}
                    path="/user/roles"
                    exact
                  />
                </Switch>
              </ErrorBoundary>

              {/*</React.StrictMode>*/}
            </MainLayout>
          </DeDUThemeProvider>
        </StateProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    )
  }
}
