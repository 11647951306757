import { DeDUPage, DeDUStack, DeDUText } from "@dedu-internal/dedu-ui"
import { useQuery } from "@tanstack/react-query"
import objectModelService from "common/services/tsApi/objectModel.service"
import MyExecutors from "pages/user/components/MyExecutors"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"

import MyCaseList from "./MyCaseList"

const CaseList: FC = () => {
  const [selectedRole, setSelectedRole] = useState<string>()
  const { t } = useTranslation()

  const objectModel = useQuery(["objectModel"], () =>
    objectModelService.getObjectModels().then(res => res || [])
  )

  const monitoredCases = useQuery(
    ["monitoredcases", selectedRole],
    () =>
      objectModelService
        .getMonitoredCases(objectModel.data?.[0].modelId || "", selectedRole)
        .then(res => res),
    {
      enabled: !!objectModel.data, //wait for objectModelId
    }
  )

  if (monitoredCases.error)
    return <DeDUText color="red">{t("common.ErrorMessage")}</DeDUText>

  return (
    <DeDUPage containerProps={{ fluid: true }} title={t("case.MyCases")}>
      <DeDUStack pt="md">
        <MyExecutors onSelect={setSelectedRole} useSelect />

        <MyCaseList
          loading={monitoredCases.isLoading}
          monitoredCases={monitoredCases.data}
        />
      </DeDUStack>
    </DeDUPage>
  )
}

export default CaseList
