import { DeDUForm, DeDUTreeSelectItem } from "@dedu-internal/dedu-ui"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import objectModelService from "common/services/tsApi/objectModel.service"
import {
  IObjectModelMonitoring,
  IUpdateObjectModelMonitoring,
} from "common/types/objectModel/objectModel.type"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

interface ResponsibilityForm {
  name: string
  objectItems: string[]
}

interface IProps {
  editItem: IObjectModelMonitoring
  objectModelId: string
  personId: string
  resetEditItem: () => void
  treeSelectItems: DeDUTreeSelectItem[]
}

const UpdateAreaResponsibility: FC<IProps> = ({
  editItem,
  objectModelId,
  personId,
  resetEditItem,
  treeSelectItems,
}) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const updateCorrectiveMonitoring = useMutation({
    mutationFn: (values: IUpdateObjectModelMonitoring) => {
      return objectModelService.updateObjectModelMonitoring(values)
    },
    onSuccess: () => {
      resetEditItem()
      setTimeout(() => {
        queryClient.invalidateQueries(["correctiveMonitorings", personId])
      }, 1000)
    },
  })

  const submit = (values: ResponsibilityForm) => {
    const updateResponsibility: IUpdateObjectModelMonitoring = {
      items: values.objectItems,
      modelId: objectModelId,
      personId: personId,
      roleId: editItem.executorRoleId?.value || "",
      roleName: editItem.name || "",
    }

    return updateCorrectiveMonitoring.mutateAsync(updateResponsibility)
  }

  return (
    <DeDUForm<ResponsibilityForm>
      items={[
        {
          key: "objectItems",
          label: t("objectModel.LinkedObjects"),
          multiple: true,
          schema: Yup.array().of(Yup.string()),
          treeSelectItems,
          type: "treeselect",
          value:
            editItem?.referencedItems?.flatMap(
              item => item?.refItemData?.modelItemId || ""
            ) || [],
        },
      ]}
      submit={{
        clearAfterSubmit: true,
        onSubmit: submit,
        resolveErrorMessage: () => t("common.ErrorMessage"),
      }}
      submitButton={{ label: t("common.Edit") }}
    />
  )
}

export default UpdateAreaResponsibility
