import {
  DeDUAccordion,
  DeDUCard,
  DeDUSkeleton,
  DeDUStack,
  DeDUText,
} from "@dedu-internal/dedu-ui"
import { useMutation, useQuery } from "@tanstack/react-query"
import objectModelService from "common/services/tsApi/objectModel.service"
import {
  IObjectModelItemName,
  IObjectModelScopeNames,
} from "common/types/objectModel/objectModel.type"
import { FC, useState } from "react"

interface IProps {
  objectModelId?: string
}

type GroupList = {
  modelId: string
  scopeData: object[]
}

const GroupList: FC<IProps> = ({ objectModelId }) => {
  const [scopesContent, setScopesContent] = useState<IObjectModelItemName[]>([])

  const { isLoading: itemsLoading, mutate } = useMutation({
    mutationFn: (scopeId: string | string[]) => {
      return objectModelService.getObjectModelItemNamesInScope(
        objectModelId!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        scopeId.toString()
      )
    },
  })

  const { data, isFetching, isInitialLoading } = useQuery<
    IObjectModelScopeNames["scopeData"]
  >(
    ["objectModelGroups"],
    () =>
      objectModelService
        .getObjectModelScopeNames(objectModelId ?? "")
        .then(res => res.scopeData),
    {
      enabled: !!objectModelId,
    }
  )

  const fetchScope = (scopeId: string | string[]) => {
    mutate(scopeId, { onSuccess: setScopesContent })
  }

  return (
    <DeDUCard isLoading={isInitialLoading}>
      <DeDUAccordion isLoading={isFetching}>
        {data &&
          Object.entries(data)?.map(([key, value]) => (
            <DeDUAccordion.Item
              key={key}
              onClick={() => fetchScope(key)}
              value={value}
            >
              <DeDUAccordion.Control>{value}</DeDUAccordion.Control>
              <DeDUAccordion.Panel>
                {itemsLoading ? (
                  <DeDUStack pt={4} spacing="xs">
                    <DeDUSkeleton h="sm" width="100%" />
                    <DeDUSkeleton h="sm" width="100%" />
                    <DeDUSkeleton h="sm" width="100%" />
                  </DeDUStack>
                ) : (
                  <>
                    {scopesContent.map(sc => (
                      <DeDUText key={sc.modelItemId}>{sc.name}</DeDUText>
                    ))}
                  </>
                )}
              </DeDUAccordion.Panel>
            </DeDUAccordion.Item>
          ))}
      </DeDUAccordion>
    </DeDUCard>
  )
}

export default GroupList
