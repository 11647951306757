import "./FieldGroup.css"

import Field from "./Field"

const FieldGroup = ({ field, fieldChanged, showBlank }) => {
  const fields = field.fields

  return (
    <fieldset key={field.id}>
      <legend>{field.label}</legend>
      {fields.map(field => {
        return (
          <Field
            key={field.id}
            field={field}
            fieldChanged={fieldChanged}
            value={showBlank ? "" : field.value}
          />
        )
      })}
    </fieldset>
  )
}

export default FieldGroup
