import { Tree } from "antd"
import { DataNode, EventDataNode } from "antd/es/tree"
import objectModelService from "common/services/tsApi/objectModel.service"
import {
  IObjectModel,
  IObjectModelItem,
} from "common/types/objectModel/objectModel.type"
import {
  ComponentProps,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react"

interface IProps {
  modelItems: IObjectModelItem[]
  objectModelId: IObjectModel["modelId"]
  onSelect?: ComponentProps<typeof Tree>["onSelect"]
  selectable?: boolean
  setModelItems: Dispatch<SetStateAction<IObjectModelItem[]>>
}

export const ItemTree: FC<IProps> = ({
  modelItems,
  objectModelId,
  onSelect,
  setModelItems,
}) => {
  const [treeNodes, setTreeNodes] = useState<DataNode[]>([])

  const modelItemToDataNode = useCallback((modelItem: IObjectModelItem) => {
    return {
      key: modelItem.modelItemId,
      title: modelItem.itemName,
    } as DataNode
  }, [])

  useEffect(() => {
    if (!treeNodes.length) {
      setTreeNodes(modelItems.map(item => modelItemToDataNode(item)))
    }
  }, [modelItemToDataNode, modelItems, treeNodes.length])

  const updateArrayRecursively = useCallback(
    (
      key: DataNode["key"],
      array: DataNode[],
      children: DataNode[]
    ): DataNode[] => {
      return array.map(node => {
        if (node.children) {
          node.children = updateArrayRecursively(key, node.children, children)
        } else if (node.key === key) {
          node.children = children
        }

        return node
      })
    },
    []
  )

  const handleLoadData = useCallback(
    async (node: EventDataNode<DataNode>) => {
      const parentId = node.key as string
      if (objectModelId && parentId) {
        objectModelService
          .getObjectModelItemsUnderParent(objectModelId, parentId)
          .then(res => {
            setModelItems(curr => [...curr, ...res])

            const newChildren = res.map(mi => modelItemToDataNode(mi))

            const updatedArray = updateArrayRecursively(
              parentId,
              treeNodes,
              newChildren
            )

            if (updatedArray) {
              setTreeNodes(updatedArray)
            }
          })
          .catch(err => {
            console.error(err) //eslint-disable-line no-console
          })
      }
    },
    [
      objectModelId,
      updateArrayRecursively,
      treeNodes,
      modelItemToDataNode,
      setModelItems,
    ]
  )

  return (
    <Tree loadData={handleLoadData} onSelect={onSelect} treeData={treeNodes} />
  )
}
