import { Select, Spin } from "antd"
import { IObjectModel } from "common/types/objectModel/objectModel.type"
import { FC } from "react"

interface IProps {
  formDisabled?: boolean
  objectModels: IObjectModel[]
  onSelected: (id: IObjectModel["modelId"]) => void
  selected: IObjectModel | undefined
}

const ObjectModelSelect: FC<IProps> = ({
  formDisabled = false,
  objectModels,
  onSelected,
  selected,
}) => {
  const onObjectModelChange = (value: IObjectModel["modelId"]) => {
    if (onSelected) {
      onSelected(value)
    }
  }

  return (
    <Spin spinning={false}>
      <Select
        disabled={formDisabled}
        onChange={onObjectModelChange}
        placeholder="Choose Object Model"
        value={selected?.modelId}
      >
        {objectModels.map(objectModel => (
          <Select.Option
            key={objectModel.modelId}
            value={objectModel.modelId}
            selected
          >
            {objectModel.name}
          </Select.Option>
        ))}
      </Select>
    </Spin>
  )
}

export default ObjectModelSelect
