import { Col, Input, InputNumber, Row, Switch } from "antd"

const Field = ({ field, fieldChanged, showBlank, type, value }) => {
  const onInputChange = val => {
    // Notify the main state list of the new value
    fieldChanged(field.id, val)
  }

  const onSwitchChange = (checked, e) => {
    fieldChanged(field.id, checked)
  }

  const renderInputType = () => {
    switch (field.type.toLowerCase()) {
      case "integer":
        return (
          <InputNumber
            defaultValue={showBlank ? "" : value}
            id={field.id}
            onChange={onInputChange}
            precision={0}
          />
        )
      case "boolean":
        return <Switch id={field.id} onChange={onSwitchChange} defaultChecked />

      case "decimal":
        return (
          <InputNumber
            defaultValue={showBlank ? "" : value}
            id={field.id}
            onChange={onInputChange}
          />
        )
      default:
        return (
          <Input
            defaultValue={showBlank ? "" : value}
            id={field.id}
            onChange={e => {
              // Notify the main state list of the new value
              fieldChanged(field.id, e.target.value)
            }}
          />
        )
    }
  }

  return (
    <div key={field.id}>
      <Row>
        <Col span={12}>
          {" "}
          <label htmlFor={field.id}>{field.label}</label>
        </Col>
        <Col span={12}>{renderInputType()}</Col>
      </Row>
    </div>
  )
}

export default Field
