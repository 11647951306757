import {
  CaseCommandServicePermissionFlags,
  CaseQueryServicePermissionFlags,
  correctiveCmdName,
  correctiveQueryName,
} from "common/permissionFlags/CorrectiveMaintenancePermissionFlags"
import {
  customerCmdName,
  CustomerCommandServicePermissionFlags,
  customerQueryName,
  CustomerQueryServicePermissionFlags,
} from "common/permissionFlags/CustomerPermissionFlags"

type Permissions = {
  [serviceName: string]:
    | typeof CaseCommandServicePermissionFlags
    | typeof CaseQueryServicePermissionFlags
    | typeof CustomerCommandServicePermissionFlags
    | typeof CustomerQueryServicePermissionFlags
  // Add entries for other services here
}

export const GetAllPermissions = () => {
  return {
    [correctiveCmdName]: CaseCommandServicePermissionFlags,
    [correctiveQueryName]: CaseQueryServicePermissionFlags,
    [customerCmdName]: CustomerCommandServicePermissionFlags,
    [customerQueryName]: CustomerQueryServicePermissionFlags,
    // TODO add more services
  } as Permissions
}
