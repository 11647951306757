import { DeDUTitle } from "@dedu-internal/dedu-ui"
import { useTranslation } from "react-i18next"

const Home = () => {
  const { t } = useTranslation()

  return <DeDUTitle>{t("home.welcome")}</DeDUTitle>
}

export default Home
