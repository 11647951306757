import { AxiosResponse } from "axios"
import {
  ICreateObjectModelItem,
  ICreateObjectModelMonitoring,
  ICreateObjectModelScope,
  IGetCaseMonitoring,
  IGetExecutors,
  IObjectModel,
  IObjectModelItem,
  IObjectModelItemName,
  IObjectModelItemPath,
  IObjectModelMonitoring,
  IObjectModelScopeNames,
  IObjectModelTemplate,
  IUpdateObjectModelMonitoring,
} from "common/types/objectModel/objectModel.type"

import http, { handleError } from "./utilities/http-common"
const url = "objectmodels"

class ObjectModelService {
  getExecutors = (objectModelId: string): Promise<IGetExecutors> =>
    http
      .get(`/${url}/executors/${objectModelId}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  getObjectModels = (): Promise<IObjectModel[]> =>
    http
      .get(`/${url}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  getObjectModelItems = (objectModelId: string): Promise<IObjectModelItem[]> =>
    http
      .get(`/${url}/simple/${objectModelId}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  getObjectModelItemsUnderParent = (
    objectModelId: string,
    parentId: string
  ): Promise<IObjectModelItem[]> =>
    http
      .get(`/${url}/simple/${parentId}/${objectModelId}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  getObjectModelTemplates = (): Promise<IObjectModelTemplate[]> =>
    http
      .get(`/${url}/templates`)
      .then(response => response.data)
      .catch(error => handleError(error))

  getObjectModelMonitorings = (
    objectModelId: string,
    userId: string
  ): Promise<IObjectModelMonitoring[]> =>
    http
      .get(`/${url}/monitorings/${objectModelId}/${userId}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  getUserMonitorings = (
    objectModelId: string
  ): Promise<IObjectModelMonitoring[]> =>
    http
      .get(`/${url}/usermonitorings/${objectModelId}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  getMonitoredCases = (
    objectModelId: string,
    executorRoleId?: string
  ): Promise<IGetCaseMonitoring[]> => {
    let address = `/${url}/monitoredcases/${objectModelId}`
    if (executorRoleId) {
      address += `/${executorRoleId}`
    }

    return http
      .get(address)
      .then(response => response.data)
      .catch(error => handleError(error))
  }

  getModelItemPaths = (
    objectModelId: string,
    queryIds: string
  ): Promise<IObjectModelItemPath[]> =>
    http
      .get(`/${url}/modelItemsPath/${objectModelId}/${queryIds}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  getObjectModelScopeNames = (
    objectModelId: string
  ): Promise<IObjectModelScopeNames> =>
    http
      .get(`/${url}/scopes/${objectModelId}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  getObjectModelItemNamesInScope = (
    objectModelId: string,
    scopeId: string
  ): Promise<IObjectModelItemName[]> =>
    http
      .get(`/${url}/scope/${objectModelId}/${scopeId}`)
      .then(response => response.data)
      .catch(error => handleError(error))

  createObjectModelItem = (
    data: ICreateObjectModelItem
  ): Promise<AxiosResponse> =>
    http.post(`/${url}/modelItem`, data).then(response => response)

  createObjectModelMonitoring = (
    data: ICreateObjectModelMonitoring
  ): Promise<AxiosResponse> =>
    http.post(`/${url}/modelItemMonitoring`, data).then(response => response)

  createObjectModelScope = (
    data: ICreateObjectModelScope
  ): Promise<AxiosResponse> =>
    http.post(`/${url}/objectModelScope`, data).then(response => response)

  updateObjectModelMonitoring = (
    data: IUpdateObjectModelMonitoring
  ): Promise<AxiosResponse> =>
    http.patch(`/${url}/modelItemMonitoring`, data).then(response => response)
}

export default new ObjectModelService()
