import i18n from "i18next"
import HttpApi from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(HttpApi)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "sv",

    interpolation: {
      escapeValue: false,
      // not needed for react as it escapes by default
    },
  })

export default i18n
