import {
  DeDUAccordion,
  DeDUActionIcon,
  DeDUCard,
  DeDUDivider,
  DeDUFlex,
  DeDUForm,
  DeDUTitle,
  DeDUTreeSelectItem,
  DeDUTreeView,
} from "@dedu-internal/dedu-ui"
import { IconEdit, IconReload } from "@tabler/icons-react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import objectModelService from "common/services/tsApi/objectModel.service"
import {
  ICreateObjectModelMonitoring,
  IObjectModelMonitoring,
} from "common/types/objectModel/objectModel.type"
import { IRole } from "common/types/role/role.type"
import {
  convertModelItemsToFormItems,
  convertMonotoringsToTreeItems,
  useObjectModelItemsUnderParent,
} from "common/utils/objectModel.utils"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { StaleTime } from "setup/StaleTime"
import * as Yup from "yup"

import UpdateAreaResponsibility from "./UpdateAreaResponsibility"

type MonitoringForm = Omit<ICreateObjectModelMonitoring, "items"> & {
  executorRole: string
  objectItems: string[]
}

const AreaResponsibility = ({
  personId,
  userRoles,
}: {
  personId: string
  userRoles: IRole[]
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [emptyRoles, setEmptyRoles] = useState<IRole[]>([])
  const [editItem, setEditItem] = useState<IObjectModelMonitoring>()
  const [objectModelId, setObjectModelId] = useState<string | null>(null)
  const [treeSelectItems, setTreeSelectItems] = useState<DeDUTreeSelectItem[]>(
    []
  )

  const objectModel = useQuery(["objectModel"], () =>
    objectModelService.getObjectModels().then(res => {
      setObjectModelId(res[0].modelId?.split("@")?.[0] || "")

      return res || []
    })
  )

  const modelItems = useQuery(
    ["objectItems"],
    () =>
      objectModelService
        .getObjectModelItems(
          objectModelId! /* eslint-disable-line @typescript-eslint/no-non-null-assertion */
        )
        .then(res => {
          setTreeSelectItems(convertModelItemsToFormItems(res))
          return res
        }),
    {
      enabled: !!objectModelId,
    }
  )

  const { isModelItemsUnderParentLoading } = useObjectModelItemsUnderParent(
    treeSelectItems,
    objectModelId,
    setTreeSelectItems
  )

  const monitorings = useQuery(
    ["correctiveMonitorings", personId],
    () =>
      objectModelService
        .getObjectModelMonitorings(
          objectModel.data?.[0]?.modelId || "",
          personId
        )
        .then(res => res || []),
    {
      enabled: !!objectModel.data, //wait for objectModelId
      staleTime: StaleTime.Short,
    }
  )

  const mutation = useMutation({
    mutationFn: (values: ICreateObjectModelMonitoring) => {
      return objectModelService.createObjectModelMonitoring(values)
    },
    onSuccess: () => {
      setTimeout(() => {
        invalidateMonitorings()
      }, 1000)
    },
  })

  const invalidateMonitorings = () => {
    queryClient.invalidateQueries(["correctiveMonitorings", personId])
  }

  const onSubmit = (values: MonitoringForm) => {
    const roleName = emptyRoles.find(
      role => role.roleId?.value === values.executorRole
    )

    const newMonotoring: ICreateObjectModelMonitoring = {
      items: values.objectItems,
      modelId: objectModel?.data?.[0]?.modelId || "",
      personId: personId,
      roleId: values.executorRole,
      roleName: roleName?.roleName || "",
    }

    return mutation.mutateAsync(newMonotoring)
  }

  useEffect(() => {
    // Remove roles in monitoring object from selectable new
    if (monitorings?.data && userRoles) {
      const rolesWithoutMonitoring = userRoles.filter(
        (ur: IRole) =>
          !monitorings?.data.some(
            mr => ur.roleId?.value === mr.executorRoleId?.value
          )
      )
      setEmptyRoles(rolesWithoutMonitoring)
    } else {
      setEmptyRoles(userRoles)
    }
  }, [monitorings?.data, userRoles])

  const editMonitoring = (
    event: React.MouseEvent,
    monitoring: IObjectModelMonitoring
  ) => {
    event.stopPropagation()
    setEditItem(monitoring)
  }

  return (
    <DeDUCard
      isLoading={objectModel.isInitialLoading || monitorings.isInitialLoading}
      sx={{ overflow: "visible" }}
    >
      <DeDUFlex align="center" justify="space-between" mb="xs">
        <DeDUTitle order={5}>{t("common.AreaResponsibility")}</DeDUTitle>
        <DeDUActionIcon
          loading={monitorings.isFetching}
          onClick={invalidateMonitorings}
        >
          <IconReload color="black" stroke={1.5} />
        </DeDUActionIcon>
      </DeDUFlex>

      <DeDUAccordion
        chevronPosition="right"
        isLoading={monitorings.isFetching}
        my="sm"
        variant="contained"
      >
        {monitorings?.data?.map((m, index) => (
          <DeDUAccordion.Item key={index} value={m.name || ""}>
            <DeDUAccordion.Control>{m.name}</DeDUAccordion.Control>
            <DeDUAccordion.Panel>
              <DeDUFlex align="center" justify="space-between">
                <DeDUTreeView data={convertMonotoringsToTreeItems(m)} />
                <DeDUActionIcon onClick={e => editMonitoring(e, m)}>
                  <IconEdit color="black" stroke={1.5} />
                </DeDUActionIcon>
              </DeDUFlex>
            </DeDUAccordion.Panel>
          </DeDUAccordion.Item>
        ))}
      </DeDUAccordion>

      {!!editItem && (
        <UpdateAreaResponsibility
          editItem={editItem}
          objectModelId={objectModel?.data?.[0]?.modelId || ""}
          personId={personId}
          resetEditItem={() => setEditItem(undefined)}
          treeSelectItems={treeSelectItems}
        />
      )}

      {emptyRoles.length > 0 && (
        <>
          <DeDUDivider my="lg" />
          <DeDUTitle order={5}>{t("common.CreateNew")}</DeDUTitle>
          <DeDUForm<MonitoringForm>
            items={[
              {
                key: "executorRole",
                label: t("common.ExecutorRole"),
                options: emptyRoles?.map(item => ({
                  label: item.roleName,
                  value: item.roleId?.value,
                })) as { label: string; value: string }[],
                schema: Yup.string().required(
                  t("common.Error.RequiredField", {
                    key: t("common.ExecutorRole"),
                  })
                ),
                type: "select",
                value: "",
              },
              {
                isLoading:
                  modelItems.isLoading || isModelItemsUnderParentLoading,
                key: "objectItems",
                label: t("objectModel.LinkedObjects"),
                multiple: true,
                schema: Yup.array().of(Yup.string()),
                treeSelectItems,
                type: "treeselect",
                value: [],
              },
            ]}
            submit={{
              clearAfterSubmit: true,
              onSubmit: onSubmit,
              resolveErrorMessage: () => t("common.ErrorMessage"),
            }}
            submitButton={{ label: t("common.Add") }}
          />
        </>
      )}
    </DeDUCard>
  )
}

export default AreaResponsibility
