import {
  DeDUCard,
  DeDUForm,
  DeDUGrid,
  DeDUPage,
  DeDUTreeSelectItem,
} from "@dedu-internal/dedu-ui"
import { useMutation, useQueries, useQuery } from "@tanstack/react-query"
import caseService from "common/services/tsApi/case.service"
import customerService from "common/services/tsApi/customer.service"
import objectModelService from "common/services/tsApi/objectModel.service"
import { ICreateCase } from "common/types/case/case.type"
import { IObjectModelItem } from "common/types/objectModel/objectModel.type"
import { phoneRegExp } from "common/utils/regex"
import React, { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

const CreateCase: React.FC = () => {
  const { t } = useTranslation()
  const [modelItems, setModelItems] = useState<IObjectModelItem[]>([])
  const [treeSelectItems, setTreeSelectItems] = useState<DeDUTreeSelectItem[]>(
    []
  )
  const [objectModelId, setObjectModelId] = useState<string | null>(null)

  const convertModelItemsToFormItems = useCallback(
    (modelItems: IObjectModelItem[]) => {
      return modelItems.map(item => ({
        label: item.itemName || "",
        value: item.modelItemId || "",
      }))
    },
    []
  )

  const getObjectModels = () => {
    return objectModelService.getObjectModels().then(res => {
      setObjectModelId(res?.[0]?.modelId?.split("@")?.[0] || "")
      return res || []
    })
  }

  const { isLoading: isObjectModelsLoading } = useQuery(
    ["objectModel"],
    getObjectModels
  )

  const getObjectModelItems = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return objectModelService.getObjectModelItems(objectModelId!).then(res => {
      setModelItems(res)
      setTreeSelectItems(convertModelItemsToFormItems(res))

      return res
    })
  }

  const { isLoading: isModelItemsLoading } = useQuery(
    ["objectItems"],
    getObjectModelItems,
    {
      enabled: !!objectModelId,
    }
  )

  const results = useQueries({
    queries: treeSelectItems.map(parentItem => ({
      enabled: !!objectModelId && !!parentItem.value,
      onSuccess: (data: IObjectModelItem[]) => {
        setModelItems(curr => [...curr, ...data])
        setTreeSelectItems(curr =>
          curr.map(c =>
            c.value === parentItem.value
              ? { ...c, children: convertModelItemsToFormItems(data) }
              : c
          )
        )
      },
      queryFn: () =>
        objectModelService
          .getObjectModelItemsUnderParent(objectModelId!, parentItem.value) // eslint-disable-line @typescript-eslint/no-non-null-assertion
          .then(res => res),
      queryKey: ["objectModelItemsUnderParent", parentItem.value],
    })),
  })
  const isModelItemsUnderParentLoading = useMemo(() => {
    return results.some(result => result.isLoading)
  }, [results])

  const { data: roles, isLoading: isRolesLoading } = useQuery(
    ["executor_roles"],
    () => customerService.getRoles().then(res => (res ? res : []))
  )

  const onSubmit = (values: ICreateCase) => {
    values.objectModelId = objectModelId ?? ""
    return caseMutation.mutateAsync(values)
  }

  const caseMutation = useMutation({
    mutationFn: (newCase: ICreateCase) => {
      if (!newCase.notifierEmail && !newCase.notifierPhone) {
        throw new Error(t("common.EmailOrPhone"))
      }
      return caseService.createCase(newCase)
    },
  })

  return (
    <DeDUPage containerProps={{ fluid: true }} title={t("case.CreateCase")}>
      <DeDUGrid columns={2}>
        <DeDUGrid.Col md={1}>
          <DeDUCard
            isLoading={isObjectModelsLoading}
            sx={{ overflow: "visible" }}
          >
            <DeDUForm<ICreateCase>
              clearButton={{ label: t("common.Clear") }}
              gridProps={{
                columns: 1,
              }}
              items={[
                {
                  isLoading: isRolesLoading,
                  key: "executorRoleId",
                  label: t("common.ExecutorRole"),
                  options: roles?.map(role => ({
                    label: role.roleName,
                    value: role.roleId?.value,
                  })) as { label: string; value: string }[],
                  placeholder: t("common.Choose"),
                  schema: Yup.string().required(t("case.Error.executorRole")),
                  type: "select",
                  value: "",
                },
                {
                  isLoading:
                    isModelItemsLoading || isModelItemsUnderParentLoading,
                  key: "modelItemId",
                  label: t("common.Location"),
                  placeholder: t("common.Choose"),
                  schema: Yup.string().required(t("common.chooseLocation")),
                  treeSelectItems,
                  type: "treeselect",
                  value: "",
                },
                {
                  key: "description",
                  label: t("case.CaseDescription"),
                  placeholder: t("case.placeholder.description"),
                  schema: Yup.string()
                    .min(2, t("case.Error.DescriptionLength"))
                    .required(t("case.Error.DescriptionLength")),
                  type: "textarea",
                  value: "",
                },
                {
                  key: "notifierName",
                  label: t("case.notifierName"),
                  placeholder: t("case.placeholder.notifierName"),
                  schema: Yup.string()
                    .min(2, t("case.Error.NameLength"))
                    .required(t("case.Error.NameLength")),
                  type: "text",
                  value: "",
                },
                {
                  key: "notifierEmail",
                  label: t("case.notifierEmail"),
                  placeholder: t("case.placeholder.notifierEmail"),
                  schema: Yup.string().email(t("common.IncorrectEmail")),
                  type: "text",
                  value: "",
                },
                {
                  key: "notifierPhone",
                  label: t("case.notifierPhone"),
                  placeholder: t("case.placeholder.notifierPhone"),
                  schema: Yup.string().matches(phoneRegExp, {
                    excludeEmptyString: true,
                    message: t("common.InvalidPhone"),
                  }),
                  type: "text",
                  value: "",
                },
              ]}
              submit={{
                clearAfterSubmit: true,
                onSubmit,
                resolveErrorMessage: error =>
                  error.name === "AxiosError"
                    ? t("case.Error.createCaseError")
                    : error.message,
                resolveSuccessMessage: () => t("case.Created"),
              }}
              submitButton={{ label: t("common.Create") }}
            />
          </DeDUCard>
        </DeDUGrid.Col>
      </DeDUGrid>
    </DeDUPage>
  )
}

export default CreateCase
