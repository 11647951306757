import { DownOutlined, UserOutlined } from "@ant-design/icons"
import { Button, Dropdown, Menu, message } from "antd"
import { useEffect, useState } from "react"

const ObjectModelTemplateDropDown = props => {
  const [template, setTemplate] = useState({
    modelItems: [],
    relations: [],
  })

  function handleMenuClick(e) {
    message.info("Click on menu item.")
    console.log("click", e)

    const clickedTemplate = template.modelItems.find(
      ({ label }) => label === e.key
    )
    if (clickedTemplate !== null && clickedTemplate !== undefined) {
      const formPayload = { modelItemJson: clickedTemplate, parentId: null }

      props.onShowModelForm({
        isNew: true,
        payload: formPayload,
        visible: true,
      })
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      {
        //Only create Menu Items for Root level templates
        template.relations.map(r => {
          if (r.fromTemplateLabel.toLowerCase().indexOf("root") > -1) {
            template.modelItems.map(t => {
              if (t.templateId === r.fromTemplateId) {
                return (
                  <Menu.Item key={t.label} icon={<UserOutlined />}>
                    {t.label}
                  </Menu.Item>
                )
              }
            })
          }
        })
      }
    </Menu>
  )

  useEffect(() => {
    if (props.template) {
      setTemplate(props.template)
    }
  }, [props.objectModelId])

  return (
    <Dropdown menu={menu}>
      <Button type="primary">
        Add Root Object <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default ObjectModelTemplateDropDown
